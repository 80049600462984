import { createGlobalStyle } from 'styled-components'
import { theme } from 'styled-tools'
import {
  MontserratBlack,
  MontserratBlackItalic,
  MontserratBold,
  MontserratBoldItalic,
  MontserratExtraBold,
  MontserratExtraBoldItalic,
  MontserratExtraLight,
  MontserratExtraLightItalic,
  MontserratItalic,
  MontserratLight,
  MontserratLightItalic,
  MontserratMedium,
  MontserratMediumItalic,
  MontserratRegular,
  MontserratSemiBold,
  MontserratSemiBoldItalic,
  MontserratThin,
  MontserratThinItalic,
  SonoRegular,
  InterRegular,
  InterMedium,
} from 'assets/fonts/font'
//import css slick-carousel
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
// themify
import 'assets/fonts/themify_font/themify-icons.css'

const GlobalStyles = createGlobalStyle`


// Montserrat
@font-face {
  font-family: 'Montserrat-Black';
  src: local('Montserrat-Black'), url(${MontserratBlack}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-BlackItalic';
  src: local('Montserrat-BlackItalic'), url(${MontserratBlackItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Bold';
  src: local('Montserrat-Bold'), url(${MontserratBold}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-BoldItalic';
  src: local('Montserrat-BoldItalic'), url(${MontserratBoldItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: local('Montserrat-ExtraBold'), url(${MontserratExtraBold}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ExtraBoldItalic';
  src: local('Montserrat-ExtraBoldItalic'), url(${MontserratExtraBoldItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ExtraLight';
  src: local('Montserrat-ExtraLight'), url(${MontserratExtraLight}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ExtraLightItalic';
  src: local('Montserrat-ExtraLightItalic'), url(${MontserratExtraLightItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Italic';
  src: local('Montserrat-Italic'), url(${MontserratItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Light';
  src: local('Montserrat-Light'), url(${MontserratLight}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-LightItalic';
  src: local('Montserrat-LightItalic'), url(${MontserratLightItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Medium';
  src: local('Montserrat-Medium'), url(${MontserratMedium}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-MediumItalic';
  src: local('Montserrat-MediumItalic'), url(${MontserratMediumItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Regular';
  src: local('Montserrat-Regular'), url(${MontserratRegular}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-SemiBold';
  src: local('Montserrat-SemiBold'), url(${MontserratSemiBold}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-SemiBoldItalic';
  src: local('Montserrat-SemiBoldItalic'), url(${MontserratSemiBoldItalic}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-Thin';
  src: local('Montserrat-Thin'), url(${MontserratThin}) format('opentype');
}
@font-face {
  font-family: 'Montserrat-ThinItalic';
  src: local('Montserrat-ThinItalic'), url(${MontserratThinItalic}) format('opentype');
}
@font-face {
  font-family: 'Sono-Regular';
  src: local('Sono-Regular'), url(${SonoRegular}) format('opentype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter-Regular'), url(${InterRegular}) format('opentype');
}
@font-face {
  font-family: 'Inter-Medium';
  src: local('Inter-Medium'), url(${InterMedium}) format('opentype');
}

  * {
    margin: 0;
    font-family: 'Montserrat-Regular';
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 16px;
  }
  body {
    height: 100%;
    #root {
      /* overflow: hidden; */
      @media(max-width : 540px) {
        background-color: #fff;
      }
    }
  }
  a, button {
    text-decoration: none;
    cursor: pointer;
    border: none;
    outline: none;
  }

  html, body,
  h1, h2, h3, h4, h5, h6,
  a, p, span,
  em, small, strong,
  sub, sup,
  mark, del, ins, strike,
  abbr, dfn,
  blockquote, q, cite,
  code, pre,
  ol, ul, li, dl, dt, dd,
  div, section, article,
  main, aside, nav,
  header, hgroup, footer,
  img, figure, figcaption,
  address, time,
  audio, video,
  canvas, iframe,
  details, summary,
  fieldset, form, label, legend,
  table, caption,
  tbody, tfoot, thead,
  tr, th, td {
    margin: 0;
    padding: 0;
    border: 0;
  }
  
  img, video, picture, canvas {
  max-width: 100%;
}

  text{
    font-family: 'NotoSansKR-Bold';
  }

  /* p {
    ${theme('fontStyle.body2')}
  } */

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  /* ::-webkit-scrollbar {
    display: none;
  } */
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 4px;
    display: block;
  }
  &::-webkit-scrollbar-thumb {
    max-width: 230px;
    background-color:  ${theme('colors.primary500')};
   

    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(180, 185, 171, 0.3);
    background-color: #b4b9ab;
  }
  outline : none;


  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .searchLocation{
    padding: 4px 0;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  
    .ant-select-item{
      line-height: 28px;
      font-weight: 400;
      font-size: 16px;
      padding: 6px 16px;
      border-radius: 0;
      color: ${(props) => props.theme.colors.primary600};

    }
  }

  // move the required icon to the end
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;

    line-height: 1;
    content: '*';
  }

  .ant-form-item {
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.primary};
    .ant-form-item-row {
      
      .ant-input-affix-wrapper-status-success,
      .ant-input-status-success,
      .ant-picker-status-success,
      .ant-select-status-success {
        border-color: ${(props) => props.theme.colors.success500};
        border-radius: 4px;
      }
      .ant-select-status-success .ant-select-selector {
        border: none;
      }
    }
  }

  .ant-drawer {
    background-color: transparent;
    @media (max-width: 768px) {
      .ant-drawer-content-wrapper {
        width: 270px !important;
      }
      .ant-drawer-content {
        background-color: transparent;
        padding-top: 56px;
      }

      .ant-drawer-header,
      .ant-drawer-body {
        background-color: white;
        padding: 16px;
        border: none;
      }

      .ant-drawer-header {
        display: none;
      }

      .ant-drawer-body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-width: 270px;
        width: 100%;
      }
    }
  }

  ul, ol {
     padding-left: 24px;
  }
  li {
    margin-bottom: 4px;
    font-family: 'Montserrat-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4E545F;
    ::marker {
    color: #1259E8;
    }
  }

  /*  */
  .tox-notifications-container {
    display: none !important;
  }

  code[class*=language-], pre[class*=language-] {
    color: #000;
    background: 0 0;
    text-shadow: 0 1px #fff;
    font-size: 14px;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    word-wrap: normal;
    line-height: 1.5;
    -moz-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}
pre[class*=language-] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}
:not(pre)>code[class*=language-], pre[class*=language-] {
    background: #f5f2f0;
}
`

export default GlobalStyles
