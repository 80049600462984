const theme = {
  font: {
    regular: 'Montserrat-Regular',
    light: 'Montserrat-Light',
    medium: 'Montserrat-Medium',
    bold: 'Montserrat-Bold',
    extraBold: 'Montserrat-ExtraBold',
    semiBold: 'Montserrat-SemiBold',
  },
  maxWidth: 1170,
  maxWidthMd: 992,
  maxWidthSm: 360,
  padding: 24,
  fontSizes: [12, 14, 16, 24, 32, 48, 64, 96, 128],
  space: [
    // margin and padding
    0, 4, 8, 16, 32, 64, 128, 256,
  ],
  colors: {
    backgroundPrimary: '#f8f8f8',
    primary600: '#020250',
    primary500: '#03045E',
    primary400: '#2B2C9E',
    primary300: '#807F96',
    primary200: '#9293EE',
    primary100: '#D5D4DC',
    primary50: '#EAEAEE',
    neutral500: '#62646A',
    secondary200: '#9FC7FC',
    secondary500: ' #1259E8',
    neutral900: '#191B1F',
    neutral400: '#9DA3AF',
    neutral300: '#D2D5DA',
    neutral100: '#F4F4F6',
    white: '#ffffff',
    blue: '#2F80ED',
    blue400: '#0077B6',
    placeholderTextColor: '#AAAAB9',
    tabPrimary: '#AAAAB9',
    success400: '#39D8BD',
    success500: '#08CEAC',
    error50: '#FBE5E0',
    error400: '#F87171',
    error500: '#EF4444',
    error600: '#870C2E',
    information500: '#547BFE',
    information800: '#151F40',
    grey: '#F8F8F8',
    grey50: '#93d3e6',
    neutral600: '#4e545f',
    neutral50: '#F9F9F9',
    neutral800: '#272A30',
    neutral700: '#3E434C',
    secondary100: '#CFE4FD',
    secondary50: '#EDF5FF',
    neutral200: '#E6E7EA',
    secondary600: '#A7A7A7',
  },
  breakPoint: {
    xs: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
}

export default theme
