export const STATUS_APPLY_JOB_CANDIDATE = {
  REVIEW: '00REVIEW',
  ACCEPT: '10ACCEPT',
  ROUND_1: '20ROUND_1',
  ROUND_2: '30ROUND_2',
  ROUND_3: '40ROUND_3',
  REJECT: '80REJECT',
}
export const STATUS_APPLY_JOB_CANDIDATE_MAP = {
  '00REVIEW': 'Consider',
  '10ACCEPT': 'Accepted',
  '20ROUND_1': 'Interview round 1',
  '30ROUND_2': 'Interview round 2',
  '40ROUND_3': 'Interview round 3',
  '80REJECT': 'Eliminated',
}

export const COMPANY_TYPE = {
  '00OUTSOURCING': 'Outsourcing',
  '10PRODUCT': 'Product',
}

export const SUCCESS_MESSAGE = {
  deleteApplication: 'Unapply successfully!',
}

export const GENDER = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
}
export const TAB_CANDIDATE = {
  myCV: 'myCV',
  appliedJobs: 'appliedJobs',
  followed: 'followed',
  myAccount: 'myAccount',
  myBlog: 'myBlog',
}

export const YL_CANDIDATE = 'YL_CANDIDATE'

export const JOB_ADDRESS = {
  '00YLINE_HCM': 'Hồ Chí Minh',
  '10YLINE_HN': 'Hà Nội ',
  '20YLINE_SEOUL': 'SEOUL',
}

export const YL_LANGUAGE = 'YL_LANGUAGE'

export const ARTICLE_STATUS = {
  WAIT_FOR_APPROVED: '00WAIT_FOR_APPROVED',
  APPROVED: '10APPROVED',
  REJECTED: '20REJECTED',
}
export const ARTICLE_STATUS_MAP = {
  '00WAIT_FOR_APPROVED': 'Consider',
  '10APPROVED': 'Posted',
  '20REJECTED': 'Reject',
}
