import { Row, Col, RowProps, ColProps, Button } from 'antd'
import styled from 'styled-components'
import { space, styleFn, variant } from 'styled-system'

interface RowProp extends RowProps, styleFn {}
interface ColProp extends ColProps, styleFn {}

interface PropsBtn {
  main?: boolean
  font?: 'MEDIUM'
  isMedium?: boolean
  outline?: boolean
}

export const Container = styled.div<{ isDetail?: boolean }>`
  width: 100%;
  max-width: ${(props) => `${props.theme.maxWidth + props.theme.padding * 2}px`};
  padding: ${(props) => `0 ${props.theme.padding}px`};
  margin: 0 auto;
  background-color: #fff;
  @media (max-width: 767px) {
    padding: ${(props) => `0 ${props.theme.space[3]}px`};
  }
`

export const StyleRow: RowProp = styled(Row)`
  ${space}
`
export const StyleCol: ColProp = styled(Col)`
  ${space}
`

export const Text = styled.div<{
  fontSize?: number
  mt?: number
  mb?: number
  color?: string
  isExtraBold?: boolean
  isBold?: boolean
  isSemiBold?: boolean
  isMedium?: boolean
  width?: string
  sm?: number
  lineHeight?: number | string
  fontWeight?: number
}>`
  font-size: ${({ fontSize }) => `${fontSize}px` || '15px'};
  font-family: ${({ isExtraBold, theme }) => isExtraBold && theme.font.extraBold};
  font-family: ${({ isSemiBold, theme }) => isSemiBold && theme.font.semiBold};

  font-family: ${({ isMedium, theme }) => isMedium && theme.font.medium};
  font-family: ${({ isBold, theme }) => isBold && theme.font.bold};
  font-weight: ${({ isBold, isExtraBold }) => (isBold || isExtraBold) && 600};
  color: ${({ color }) => color ?? 'inherit'};
  margin-top: ${({ mt }) => mt && `${mt}px`};
  margin-bottom: ${({ mb }) => mb && `${mb}px`};
  width: ${({ width }) => width || '100%'};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  @media (max-width: 540px) {
    font-size: ${({ sm }) => (sm ? `${sm}px` : '15px')};
  }
`

export const StyleLine = styled.div<{ hide?: string }>`
  height: 1px;
  background-color: #e5e8e7;
  display: block;

  @media (min-width: 768px) {
    width: 100%;
    margin: 0;
    display: ${({ hide }) => (hide === 'md' ? `none` : 'block')};
  }
`

export const Title = styled.div`
  font-family: ${(props) => props.theme.font.bold};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  color: ${(props) => props.theme.colors.neutral900};
  text-align: left;
  margin-bottom: 56px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`

export const TextYL = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: ${(prop) => prop.theme.colors.white};
  margin-bottom: 40px;
`

export const ContainerYL = styled.div`
  max-width: ${(props) => `${props.theme.maxWidth}px`};
  margin: 0 auto;
`
export const TitleCommon = styled.div`
  font-family: ${(props) => props.theme.font.semiBold};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.primary};

  @media (max-width: 600px) {
    font-size: 18px;
  }
`
export const TagJob = styled.div`
  padding: 2px 6px;
  height: 24px;
  background: ${(props) => props.theme.colors.secondary50};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.secondary500};
`

export const Tag = styled.div`
  background: ${(props) => props.theme.colors.secondary50};
  border-radius: 8px;
  padding: 2px 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.secondary500};
  cursor: pointer;
`

export const BtnAction = styled(Button)<{
  fontFamily?: 'medium'
  main?: boolean
  variant?: 'main' | 'outline'
  isMedium?: boolean
  outline?: boolean
}>`
  span {
    font-family: ${({ isMedium, theme, fontFamily }) => isMedium && theme.font[fontFamily]};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 8px;
  min-width: 120px;
  height: 40px;
  cursor: pointer;
  border: 1px solid ${(props) => props.variant === 'main' && props.theme.colors.primary200};
  background-color: ${(props) => (props.variant === 'main' ? props.theme.colors.primary500 : props.theme.colors.white)};
  color: ${(props) => (props.variant === 'main' ? props.theme.colors.white : props.theme.colors.primary600)};
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;

  &.ant-btn-default:not(:disabled):hover {
    opacity: 0.9;
    background-color: ${(props) => props.variant === 'outline' && '#F4F4F6'};
    color: ${(props) => (props.variant === 'outline' ? props.theme.colors.primary600 : props.theme.colors.white)};
    border-color: unset;
  }
`
export const Tags = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.secondary500};
  background: ${(props) => props.theme.colors.secondary50};
  border-radius: 8px;
  padding: 2px 6px;
  height: 24px;
`
export const HRLine = styled.div<{
  color: 'secondary100' | 'secondary200' | 'neutral300'
}>`
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.colors[props.color]};
`
export const TitleText = styled.div`
  font-family: ${(props) => props.theme.font.semiBold};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.neutral900};
  @media (max-width: 576px) {
    font-size: 18px;
  }
`
export const Heading = styled.div`
  font-family: ${(props) => props.theme.font.semiBold};
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.neutral900};
`
