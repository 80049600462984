/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dropdown } from 'antd'
import { TAB_CANDIDATE } from 'common/constants/codeConstant'
import Endpoints from 'common/constants/endpoints'
import { PARTNER_SITE, ROUTES } from 'common/constants/routes'
import useAuthority from 'hooks/useAuthority'
import useWindowSize from 'hooks/useWindowSize'
import { CaretDown, CheckCircle, FileText, HeartStraight, NotePencil, SignOut, User } from 'phosphor-react'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { RootState } from 'redux/reducer'
import { logout } from 'redux/reducer/candidateReducer'
import { useAppDispatch } from 'redux/store'
import { getByCompany } from 'services/employer'
import { getAllByTitle, getAllSkill } from 'services/tag'
import { isEmpty } from 'utils/handleObject'
import LanguageDropdown from '../LanguageDropdown'
import Logo from '../Logo'
import Notifications from '../Notifications'
import HeaderMobile from './HeaderMobile'
import MenuAllJob from './MenuAllJob'
import * as S from './styles'

const Header = () => {
  const { t } = useTranslation('common')
  const navigation = useNavigate()
  const location = useLocation()
  const pathname = location?.pathname
  const windowSize = useWindowSize()
  const [isActive, setActive] = useState<boolean>(false)
  const [isScroll, setIsScroll] = useState<boolean>(false)
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false)
  const [dataMenu, setDataMenu] = useState({
    jobsBySkill: [],
    jobsByTitle: [],
    jobsByCompany: [],
    jobsByCity: [],
  })

  const items = [
    {
      key: '1',
      label: t('header.myCV'),
      value: TAB_CANDIDATE.myCV,
      icon: <FileText size={24} />,
    },
    {
      key: '2',
      label: t('header.appliedJobs'),
      value: TAB_CANDIDATE.appliedJobs,
      icon: <CheckCircle size={24} />,
    },
    {
      key: '3',
      label: t('header.followed'),
      value: TAB_CANDIDATE.followed,
      icon: <HeartStraight size={24} />,
    },
    {
      key: '4',
      label: t('header.myAccount'),
      value: TAB_CANDIDATE.myAccount,
      icon: <User size={24} />,
    },
    {
      key: '5',
      label: t('header.myBlog'),
      value: TAB_CANDIDATE.myBlog,
      icon: <NotePencil size={24} />,
    },
    {
      key: '6',
      label: t('header.signOut'),
      value: 'signOut',
      icon: <SignOut size={24} />,
    },
  ]

  const dataCity = [
    { id: 'hcm', name: t('findJob.hcm') },
    { id: 'hn', name: t('findJob.vn') },
    { id: 'seoul', name: t('findJob.seoul') },
  ]

  const fetchDataMenuHeader = async () => {
    setDataMenu({
      jobsBySkill: (await getDataAllSkill()) || dataMenu.jobsBySkill,
      jobsByTitle: (await getDataAllTitle()) || dataMenu.jobsByTitle,
      jobsByCompany: (await getDataCompanyMenu()) || dataMenu.jobsByCompany,
      jobsByCity: dataCity,
    })
  }

  const getDataAllSkill = async () => {
    const response: any = await getAllSkill()
    return response?.result ? response.data : []
  }
  const getDataAllTitle = async () => {
    const response: any = await getAllByTitle()
    return response?.result ? response.data : []
  }
  const getDataCompanyMenu = async () => {
    const response: any = await getByCompany()
    return response?.result ? response.data : []
  }
  const userAuthority = useAuthority()
  const dispatch = useAppDispatch()
  const candidateReducer = useSelector((state: RootState) => state.candidateReducer)

  const handleMouseMove = (e: SyntheticEvent) => {
    if (e && pathname === '/') {
      setActive(true)
    }
  }
  const handleMouseLeave = () => {
    if (pathname === '/') {
      setActive(false)
    }
  }

  const controlDirection = () => {
    if (window.scrollY > 0) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  }

  useEffect(() => {
    if (pathname === '/') {
      setIsScroll(false)
      window.addEventListener('scroll', controlDirection)
      return () => {
        window.removeEventListener('scroll', controlDirection)
      }
    } else {
      setIsScroll(true)
    }
  }, [pathname])

  useEffect(() => {
    fetchDataMenuHeader()
  }, [])

  /**
   * event when click on the list of candidate management
   * @returns {React.ReactNode}
   */
  const renderManagementUser = (): React.ReactNode => {
    return (
      <S.MenuDropdown>
        {items.map((item, index) => {
          const handleClick = () => {
            const option = {
              pathname: Endpoints.CANDIDATE,
              search: `?tab=${item.value}`,
            }

            if (index === 5) {
              dispatch(logout({}))
              return navigation(
                {
                  pathname: ROUTES.HOME,
                },
                { replace: true },
              )
            }
            navigation(option)
          }
          return (
            <S.BoxItem key={index} className={` ${index === 5 ? 'color-red' : ''}`} onClick={handleClick}>
              <span>{item.icon}</span>
              <span>{item.label}</span>
            </S.BoxItem>
          )
        })}
      </S.MenuDropdown>
    )
  }

  const desktopRender = () => {
    return (
      <S.HeaderMenu>
        <S.Box onMouseMove={handleMouseMove}>
          <S.NavBarLink to="/" className={`flex ${isActive ? 'textColor' : ''}`}>
            {isActive || isScroll ? (
              //TODO: wait logo
              <Logo style={{ marginRight: 12 }} height={40} width={30} />
            ) : (
              <Logo style={{ marginRight: 12 }} height={40} width={30} dark />
            )}
            <h1>yLine</h1>
          </S.NavBarLink>
          <S.WrapNavLink>
            <div
              className="menu-all-job"
              onMouseLeave={() => setMenuDropDownOpen(false)}
              onMouseOver={() => setMenuDropDownOpen(true)}
            >
              <S.NavBarLink to={ROUTES.JOB_ALL} className={isActive ? 'textColor' : ''}>
                {t('header.allJobs')}
              </S.NavBarLink>
              {/*  */}
              {isMenuDropDownOpen && <MenuAllJob data={dataMenu} setMenuDropDownOpen={setMenuDropDownOpen} />}
              {/*  */}
            </div>
            <S.NavBarLink to="/ITCompanies" className={isActive ? 'textColor' : ''}>
              {t('header.itCompanies')}
            </S.NavBarLink>
            <S.NavBarLink to="/ITNews" className={isActive ? 'textColor' : ''}>
              {t('header.itNew')}
            </S.NavBarLink>
            <S.NavBarLink to="/ITBlog" className={isActive ? 'textColor' : ''}>
              {t('header.itBlog')}
            </S.NavBarLink>
          </S.WrapNavLink>
        </S.Box>
        <S.Box onMouseMove={handleMouseMove}>
          {isEmpty(userAuthority) ? (
            <>
              <S.LinkStyle href={PARTNER_SITE} className={isActive ? 'textColor' : ''}>
                {t('header.employers')}
              </S.LinkStyle>

              <S.NavBarLink to={ROUTES.LOGIN} className={isActive ? 'textColor' : ''}>
                <S.ButtonStyle className={isActive || isScroll ? 'accent' : ''}>{t('button.login')}</S.ButtonStyle>
              </S.NavBarLink>
            </>
          ) : (
            <>
              <Dropdown dropdownRender={renderManagementUser}>
                <S.WrapCandidate className={isActive ? 'textColor' : ''}>
                  <img
                    src={candidateReducer?.avatar ? candidateReducer?.avatar : 'https://i.pravatar.cc/300'}
                    alt={candidateReducer?.avatar}
                    className="user-icon"
                  />
                  <S.BoxUser className={isActive ? 'textColor' : ''}>
                    <div className="user-text">
                      <div className="user-name">
                        {candidateReducer?.fullName ? candidateReducer?.fullName : candidateReducer?.email}
                      </div>
                      <CaretDown size={16} />
                    </div>
                  </S.BoxUser>
                </S.WrapCandidate>
              </Dropdown>
              <Notifications isScroll={isScroll} isActive={isActive} />
            </>
          )}

          <LanguageDropdown isActive={isActive} />
        </S.Box>
      </S.HeaderMenu>
    )
  }

  return (
    <S.StyledHeader
      id="header-container"
      className={`${pathname === '/' ? 'home' : ''}  ${isActive || pathname !== '/' || isScroll ? 'bgHeader' : ''} ${
        isScroll ? 'scroll' : ''
      }`}
      onMouseLeave={handleMouseLeave}
    >
      <S.Container>
        {windowSize.width <= 768 ? (
          <HeaderMobile isActive={isActive} isScroll={isScroll} handleMouseMove={handleMouseMove} dataMenu={dataMenu} />
        ) : (
          desktopRender()
        )}
      </S.Container>
    </S.StyledHeader>
  )
}

export default Header
