const Endpoints = {
  DOWNLOAD: '/report',
  LOGIN: '/auth/sign-in',
  SIGNUP: '/auth/sign-up-employee',
  FORGE_PASSWORD: '/auth/forget-password',
  RESET_PASSWORD: '/auth/reset-password',
  CANDIDATE: '/candidate',
  EMPLOYER: '/employer',
}

export default Endpoints
