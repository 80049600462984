/**
 * Check value if the object has value return key in string
 * @param object Target Object
 * @param value value need to compare with value in object
 * @returns key of Object that has same value input
 */
function getKeyByValue(object: any, value: any) {
  return Object.keys(object).find((key) => object[key] === value)
}
export default getKeyByValue
