/* eslint-disable @typescript-eslint/ban-types */
import { Row } from 'antd'
import { ROUTES } from 'common/constants/routes'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'

import * as S from './styles'

type Props = {
  data: any
  setMenuDropDownOpen: any
}

const JobByCompany = ({ data, setMenuDropDownOpen }: Props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const goToCompanyPage = (query: string) => {
    navigate(`${ROUTES.EMPLOYER}/${query}`)
  }
  return (
    <S.ListJobContainer>
      <Row>
        {data &&
          data?.map((item: any) => (
            <S.Item onClick={() => goToCompanyPage(item.nameCode)} key={item.id}>
              {item.name}
            </S.Item>
          ))}
      </Row>
      <S.ViewAllWrap>
        <NavLink to={`${ROUTES.JOB_LIST}/jobsByCompany`} onClick={() => setMenuDropDownOpen(false)}>
          <span>
            {t('MENU_ALL_JOB.jobs_by_company')} <CaretRight size={20} />
          </span>
        </NavLink>
      </S.ViewAllWrap>
    </S.ListJobContainer>
  )
}

export default JobByCompany
