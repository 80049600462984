import Endpoints from 'common/constants/endpoints'
import axios from 'services/axiosInstance'
import { Company } from 'types/company'
import { AllJobByCompany } from 'types/job'
import { PromiseResult } from 'types/promiseResult'

const URL_DETAIL_COMPANY = '/companies/'
const URL_ALL_JOB_BY_COMPANY = '/jobs/get-all-job-by-company/'
const URL_MENU_JOB_BY_COMPANY = '/companies/name'

export interface PromiseCompanyResult extends PromiseResult {
  data: Company
}
export interface PromiseJobResult extends PromiseResult {
  data: AllJobByCompany
}
// ------------------------ ACTION -----------------------------------------
export const getCompanyDetail = async (companyName: string): Promise<PromiseCompanyResult> => {
  const response = await axios().get(`${URL_DETAIL_COMPANY}${companyName}`)
  if (response?.data?.result) {
    return response.data
  }
}

export const getAllJobByCompany = async (params: {
  companyName: string
  pageIndex?: number
  pageSize?: number
}): Promise<PromiseJobResult> => {
  const requestParams = {
    nameCode: params?.companyName,
    pageIndex: params?.pageIndex,
    pageSize: params?.pageSize,
  }
  const response = await axios().post(URL_ALL_JOB_BY_COMPANY, requestParams)
  return response?.data
}

export const getByCompany = async (): Promise<PromiseCompanyResult> => {
  try {
    const response = await axios()
      .get(URL_MENU_JOB_BY_COMPANY)
      .catch((error) => error.response)
    return response?.data
  } catch (error: any) {
    return error
  }
}

export const getCompaniesByTitle = async (title: string): Promise<PromiseCompanyResult> => {
  try {
    const response = await axios()
      .get(`homepage${title}`)
      .catch((error) => error.response)
    return response?.data
  } catch (error: any) {
    return error
  }
}
