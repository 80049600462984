import React from 'react'
import './style.css'

interface Props {
  overlay?: any
}

export default function Loading(props: Props) {
  return (
    <div className="bg-loading">
      <div className="content-loading">
        <div className="border-loading" />
        <div className="icon-loading">yLine</div>
      </div>
    </div>
  )
}
