/* eslint-disable @typescript-eslint/ban-types */
import { Row } from 'antd'
import { ROUTES } from 'common/constants/routes'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { directToSearchPage } from '../JobBySkill'

import * as S from './styles'

type Props = {
  data: any
  setMenuDropDownOpen: any
}

const JobByTitle = ({ data, setMenuDropDownOpen }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  return (
    <S.ListJobContainer>
      <Row>
        {data &&
          data?.map((item: any) => (
            <S.Item
              key={item.id}
              onClick={() => {
                directToSearchPage(navigate, item.name)
              }}
            >
              {item.name}
            </S.Item>
          ))}
      </Row>
      <S.ViewAllWrap>
        <NavLink to={`${ROUTES.JOB_LIST}/jobsByTitle`} onClick={() => setMenuDropDownOpen(false)}>
          <span>
            {t('MENU_ALL_JOB.jobs_by_title')}
            <CaretRight size={20} />
          </span>
        </NavLink>
      </S.ViewAllWrap>
    </S.ListJobContainer>
  )
}

export default JobByTitle
