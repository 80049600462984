/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Drawer } from 'antd'
import { TAB_CANDIDATE, YL_CANDIDATE, YL_LANGUAGE } from 'common/constants/codeConstant'
import Endpoints from 'common/constants/endpoints'
import { PARTNER_SITE, ROUTES } from 'common/constants/routes'
import { convertLang } from 'components/common/LanguageDropdown'
import Logo from 'components/common/Logo'
import Notifications from 'components/common/Notifications'
import useAuthority from 'hooks/useAuthority'
import {
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CheckCircle,
  FileText,
  HeartStraight,
  List,
  NotePencil,
  SignOut,
  User,
  X,
} from 'phosphor-react'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { isEmpty } from 'utils/handleObject'
import { deleteLocalStorage, reactLocalStorage } from 'utils/localStorage'
import * as S from '../styles'

interface Props {
  dataMenu?: any
  isScroll?: boolean
  isActive?: boolean
  handleMouseMove?: (e: SyntheticEvent) => void
}

const HeaderMobile = ({ dataMenu, isActive, isScroll, handleMouseMove }: Props) => {
  const navigation = useNavigate()
  const location = useLocation()
  const pathname = location?.pathname
  const [open, setOpen] = useState(false)
  // const [isActive, setActive] = useState<boolean>(false)
  // const [isScroll, setIsScroll] = useState<boolean>(false)
  const [openSubMenu, setOpenSubMenu] = useState<any>(null)
  const [openJobList, setOpenJobList] = useState<any>(null)
  const userAuthority = useAuthority()

  // const handleMouseMove = (e: SyntheticEvent) => {
  //   if (e && pathname === '/') {
  //     setActive(true)
  //   }
  // }
  // const handleMouseLeave = () => {
  //   if (pathname === '/') {
  //     setActive(false)
  //   }
  // }
  const { t, i18n } = useTranslation('common')

  useEffect(() => {
    if (!open) setOpenJobList(null)
  }, [open])

  useEffect(() => {
    onClose()
  }, [location])

  const items = [
    {
      key: '1',
      label: t('header.myCV'),
      value: TAB_CANDIDATE.myCV,
      icon: <FileText size={24} />,
    },
    {
      key: '2',
      label: t('header.appliedJobs'),
      value: TAB_CANDIDATE.appliedJobs,
      icon: <CheckCircle size={24} />,
    },
    {
      key: '3',
      label: t('header.followed'),
      value: TAB_CANDIDATE.followed,
      icon: <HeartStraight size={24} />,
    },
    {
      key: '4',
      label: t('header.myAccount'),
      value: TAB_CANDIDATE.myAccount,
      icon: <User size={24} />,
    },
    {
      key: '5',
      label: t('header.myBlog'),
      value: TAB_CANDIDATE.myBlog,
      icon: <NotePencil size={24} />,
    },
    {
      key: '6',
      label: t('header.signOut'),
      value: 'signOut',
      icon: <SignOut size={24} />,
    },
  ]

  const menuItems = [
    {
      id: 0,
      label: t('header.allJobs'),
      value: 'allJobs',
      child: [
        {
          label: t('MENU_ALL_JOB.jobs_by_skill'),
          value: 'jobsBySkill',
        },
        {
          label: t('MENU_ALL_JOB.jobs_by_title'),
          value: 'jobsByTitle',
        },
        {
          label: t('MENU_ALL_JOB.jobs_by_company'),
          value: 'jobsByCompany',
        },
        {
          label: t('MENU_ALL_JOB.jobs_by_city'),
          value: 'jobsByCity',
        },
      ],
    },
    {
      id: 1,
      label: t('header.itCompanies'),
      value: 'itCompanies',
      link: '/ITCompanies',
    },
    {
      id: 2,
      label: t('header.itBlog'),
      value: 'itNews',
      link: '/ITBlog',
    },
    {
      id: 3,
      label: t('header.itNew'),
      value: 'itNews',
      link: '/ITNews',
    },
  ]

  const menuLanguage = [
    {
      id: 0,
      label: t('lang.title'),
      value: 'language',
      child: [
        { label: t('lang.nameVn'), value: convertLang.vi },
        { label: '한국어', value: convertLang.ko },
      ],
    },
  ]

  //show menu account on mobile
  const renderMobileUserManagementMenu = (): React.ReactNode => {
    return items.map((item: any, index: number) => {
      const handleClick = () => {
        const option = {
          pathname: Endpoints.CANDIDATE,
          search: `?tab=${item.value}`,
        }

        if (index === 5) {
          deleteLocalStorage(YL_CANDIDATE)
          return navigation(
            {
              pathname: ROUTES.HOME,
            },
            { replace: true },
          )
        }
        navigation(option, { replace: true })
      }
      return (
        item?.key === '6' || (
          <S.BoxItem key={item?.key} onClick={handleClick}>
            <div className="menuItem">
              <span className="menuMobile-icon">{item?.icon}</span>
              <span className="menuMobile-label">{item?.label}</span>
            </div>
            <CaretRight size={16} />
          </S.BoxItem>
        )
      )
    })
  }

  const renderDropdownMenuMobile = (menu: any): React.ReactNode => {
    return menu.map((item: any) => {
      const handleClick = () => {
        if (item && item?.link) {
          navigation(item?.link)
          setOpenSubMenu(null)
        } else setOpenSubMenu(openSubMenu === item?.value ? null : item.value)
      }

      //handle click on child menu item
      const handleChildMenuClick = (element: any) => {
        if (item?.value === 'language') {
          i18n.changeLanguage(element.value)
          reactLocalStorage.set(YL_LANGUAGE, JSON.stringify(element.value))
        } else setOpenJobList(element)
      }

      return (
        <S.MenuItem key={item?.id}>
          <S.BoxItem onClick={() => handleClick()}>
            <div className="menuItem">
              <span className="menuMobile-label">{item?.label}</span>
            </div>
            {openSubMenu === item?.value ? <CaretUp size={16} /> : <CaretDown size={16} />}
          </S.BoxItem>
          {item?.child &&
            item?.value === openSubMenu &&
            item?.child.map((element: any) => (
              <S.BoxItem key={element?.value} onClick={() => handleChildMenuClick(element)}>
                <div className="menuItem submenu-item">
                  <span className="menuMobile-label">{element?.label}</span>
                </div>
                <CaretRight size={16} />
              </S.BoxItem>
            ))}
        </S.MenuItem>
      )
    })
  }

  //show all jobs - list
  const renderItemJobTypeMobile = () => {
    return dataMenu[openJobList?.value]?.map((item: any) => {
      //handle click on job item
      const handleClickItem = (query: string) => {
        switch (openJobList?.value) {
          case 'jobsBySkill': {
            navigation(`${ROUTES.JOB_ALL}?nameJob=${query}`)
            break
          }
          case 'jobsByTitle': {
            navigation(`${ROUTES.JOB_ALL}?nameJob=${query}`)
            break
          }
          case 'jobsByCompany': {
            navigation(`${ROUTES.EMPLOYER}/${query}`)
            break
          }
          default: {
            navigation(`${ROUTES.JOB_ALL}?location=${query}`)
            break
          }
        }
      }
      return (
        <S.BoxItem
          key={item?.id}
          onClick={() => handleClickItem(openJobList?.value === 'jobsByCity' ? item?.id : item?.name)}
        >
          <div className="menuItem submenu-item">
            <span className="menuMobile-label">{item?.name}</span>
          </div>
        </S.BoxItem>
      )
    })
  }

  const onClose = () => {
    setOpenJobList(null)
    setOpen(false)
  }

  return (
    <S.HeaderMenu>
      <Drawer
        size="default"
        closeIcon={<X size={24} />}
        className="drawer-mobile"
        title={
          <S.TitleDrawer>
            <Logo />
            yLine
          </S.TitleDrawer>
        }
        placement="left"
        closable
        open={open}
        onClose={onClose}
      >
        {isEmpty(userAuthority) ? (
          <S.MobileButtonLoginWrap>
            <S.NavBarLink to={ROUTES.LOGIN} className={isActive ? 'textColor' : ''}>
              <S.MobileButtonLogin>{t('button.login')}</S.MobileButtonLogin>
            </S.NavBarLink>
          </S.MobileButtonLoginWrap>
        ) : (
          <>
            <S.WrapCandidate className={isActive ? 'textColor' : ''}>
              <img
                src={userAuthority?.avatar ? userAuthority?.avatar : 'https://i.pravatar.cc/300'}
                alt={userAuthority?.avatar}
                className="user-icon"
              />
              <S.BoxUser className={isActive ? 'textColor' : ''}>
                <div className="user-text">
                  <div className="user-name">
                    {userAuthority?.fullName ? userAuthority?.fullName : userAuthority?.email}
                  </div>
                </div>
              </S.BoxUser>
            </S.WrapCandidate>
            <S.MobileLinkWrap>
              <S.BoxItem>
                <Notifications isScroll={isScroll} isActive={isActive} />
                <CaretRight size={16} />
              </S.BoxItem>
              {renderMobileUserManagementMenu()}
            </S.MobileLinkWrap>
          </>
        )}

        <S.MobileLine />
        <S.MobileLinkWrap>{renderDropdownMenuMobile(menuItems)}</S.MobileLinkWrap>

        <S.MobileLine />

        <S.MobileLinkWrap>
          <S.BoxItem>
            <div className="menuItem">
              <span className="title">{t('header.general')}</span>
            </div>
          </S.BoxItem>
          <S.BoxItem
            onClick={() => {
              navigation({ pathname: ROUTES.HOME })
            }}
          >
            <div className="menuItem">
              <span>{t('header.home')}</span>
            </div>
          </S.BoxItem>
          {renderDropdownMenuMobile(menuLanguage)}
        </S.MobileLinkWrap>
        {!isEmpty(userAuthority) && (
          <S.BoxItem
            className="color-red"
            onClick={() => {
              deleteLocalStorage(YL_CANDIDATE)
              navigation({ pathname: ROUTES.HOME }, { replace: true })
            }}
          >
            {items[4].icon}
            <span className="color-red">{items[4].label}</span>
          </S.BoxItem>
        )}
      </Drawer>
      <Drawer
        size="default"
        closeIcon={<CaretLeft size={24} />}
        className="drawer-mobile"
        placement="left"
        closable
        open={openJobList && open}
      >
        <S.MobileLinkWrap>
          <S.BoxItem onClick={() => setOpenJobList(null)}>
            <div className="menuItem">
              <CaretLeft size={16} />
              <span className="title">{openJobList?.label}</span>
            </div>
          </S.BoxItem>
          {openJobList && renderItemJobTypeMobile()}
          {openJobList?.value !== 'jobsByCity' && (
            <>
              <S.MobileLine />
              <S.ViewAllWrap>
                <span>
                  View all {openJobList?.label} <CaretRight size={20} />
                </span>
              </S.ViewAllWrap>
            </>
          )}
        </S.MobileLinkWrap>
      </Drawer>
      <div className="toggle-menu" onClick={() => setOpen(!open)}>
        {open ? <X size={24} /> : <List size={24} />}
      </div>
      <S.HeaderMenu>
        <Drawer
          size="default"
          closeIcon={<X size={24} />}
          className="drawer-mobile"
          title={
            <S.TitleDrawer>
              {isActive || isScroll ? (
                //TODO: wait logo
                <Logo style={{ marginRight: 12 }} height={40} width={30} dark />
              ) : (
                <Logo style={{ marginRight: 12 }} height={40} width={30} />
              )}
              yLine
            </S.TitleDrawer>
          }
          placement="left"
          closable
          open={open}
          onClose={onClose}
        >
          {isEmpty(userAuthority) ? (
            <S.MobileButtonLoginWrap>
              <S.NavBarLink to={ROUTES.LOGIN} className={isActive ? 'textColor' : ''}>
                <S.MobileButtonLogin>{t('button.login')}</S.MobileButtonLogin>
              </S.NavBarLink>
            </S.MobileButtonLoginWrap>
          ) : (
            <>
              <S.WrapCandidate className={isActive ? 'textColor' : ''}>
                <img
                  src={userAuthority?.avatar ? userAuthority?.avatar : 'https://i.pravatar.cc/300'}
                  alt={userAuthority?.avatar}
                  className="user-icon"
                />
                <S.BoxUser className={isActive ? 'textColor' : ''}>
                  <div className="user-text">
                    <div className="user-name">
                      {userAuthority?.fullName ? userAuthority?.fullName : userAuthority?.email}
                    </div>
                  </div>
                </S.BoxUser>
              </S.WrapCandidate>
              <S.MobileLinkWrap>
                <S.BoxItem>
                  <Notifications isScroll={isScroll} isActive={isActive} />
                </S.BoxItem>

                {renderMobileUserManagementMenu()}
              </S.MobileLinkWrap>
              <S.MobileLine />
            </>
          )}

          <S.MobileLinkWrap>{renderDropdownMenuMobile(menuItems)}</S.MobileLinkWrap>
          <S.MobileLine />

          <S.MobileLinkWrap>
            <S.BoxItem>
              <div className="menuItem">
                <span className="title">{t('header.general')}</span>
              </div>
            </S.BoxItem>
            <S.BoxItem
              onClick={() => {
                navigation({ pathname: ROUTES.HOME })
              }}
            >
              <div className="menuItem">
                <span>{t('header.home')}</span>
              </div>
            </S.BoxItem>
            {renderDropdownMenuMobile(menuLanguage)}
          </S.MobileLinkWrap>
          {!isEmpty(userAuthority) ? (
            <S.BoxItem
              className="color-red"
              onClick={() => {
                deleteLocalStorage(YL_CANDIDATE)
                navigation({ pathname: ROUTES.HOME }, { replace: true })
              }}
            >
              {items[5].icon}
              <span className="color-red">{items[5].label}</span>
            </S.BoxItem>
          ) : (
            <S.BoxItem>
              <S.LinkStyle href={PARTNER_SITE} className={isActive ? 'textColor' : ''}>
                {t('header.employers')}
                <div className="arrow-wrap">
                  <CaretRight size={24} />
                </div>
              </S.LinkStyle>
            </S.BoxItem>
          )}
        </Drawer>
        <Drawer
          size="default"
          closeIcon={<CaretLeft size={24} />}
          className="drawer-mobile"
          placement="left"
          closable
          open={openJobList && open}
        >
          <S.MobileLinkWrap>
            <S.BoxItem onClick={() => setOpenJobList(null)}>
              <div className="menuItem">
                <CaretLeft size={16} />
                <span className="title">{openJobList?.label}</span>
              </div>
            </S.BoxItem>
            {openJobList && renderItemJobTypeMobile()}
            {openJobList?.value !== 'jobsByCity' && (
              <>
                <S.MobileLine />
                <S.ViewAllWrap>
                  <span>
                    {openJobList?.label} <CaretRight size={20} />
                  </span>
                </S.ViewAllWrap>
              </>
            )}
          </S.MobileLinkWrap>
        </Drawer>

        <S.Box onMouseMove={handleMouseMove}>
          <S.NavBarLink to="/" className={`flex ${isActive ? 'textColor' : ''}`}>
            {isActive || isScroll ? (
              //TODO: wait logo
              <Logo style={{ marginRight: 12 }} height={40} width={30} />
            ) : (
              <Logo style={{ marginRight: 12 }} height={40} width={30} dark />
            )}
            <h1>yLine</h1>
          </S.NavBarLink>
        </S.Box>
      </S.HeaderMenu>
    </S.HeaderMenu>
  )
}

export default HeaderMobile
