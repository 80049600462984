import { Collapse } from 'antd'
import styled from 'styled-components'

interface Props {
  bg?: string
}
export const StyledFooter = styled.footer<Props>`
  background-image: url('${(prop) => prop.bg}');
  background-size: cover;
  padding: 80px 16px;
  @media (max-width: 768px) {
    padding: 24px 16px;
    background-image: none;
    background: linear-gradient(90deg, #03045e 0%, #00b4d8 100%);
  }
`
export const Container = styled.div`
  max-width: ${(props) => `${props.theme.maxWidth}px`};
  margin: 0 auto;
`
export const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }
`
export const TitleFooter = styled.div`
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  h1 {
    color: ${(props) => props.theme.colors.white};
    font-family: ${(props) => props.theme.font.bold};
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`
export const LinkWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  column-gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    row-gap: 20px;
  }
`
export const Link = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.white};
  @media (max-width: 768px) {
    font-size: 14px;

  }

`

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  row-gap: 32px;
  max-width: 290px;

  @media (max-width: 768px) {
    order: 2;
    row-gap: 12px;
    max-width: 100%;
  }
`
export const Paragraph = styled.div`
  display: inline-block;
  font-family: ${(props) => props.theme.font.medium};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.white};
  .phone {
    font-family: ${(props) => props.theme.font.medium};
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.white};
  }
`

export const BottomText = styled.div`
  font-family: ${(props) => props.theme.font.regular};
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  padding-top: 24px;
  width: 100%;
  border-top: 0.5px solid #fff;
  @media (max-width: 768px) {
    text-align: center;
    padding-top: 12px;
  }
`

export const FooterCollapse = styled(Collapse)`
  width: 100%;
  border: none;

  display: flex;
  flex-direction: column;
  gap: 20px;
`
export const PanelCollapse = styled(Collapse.Panel)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &.ant-collapse-item {
    border-bottom: none;
    .ant-collapse-header {
      font-family: ${(props) => props.theme.font.medium};
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
      padding: 0;
    }
    .ant-collapse-content {
      background-color: transparent;
      border-top: none;
      .ant-collapse-content-box {
        padding: 0 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        div {
          font-family: ${(props) => props.theme.font.medium};
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #ffffff;
        }
      }
    }
  }
`
