import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 13 9" fill="none" {...props}>
    <path d="M11.5 1.5L4.5 8.5L1 5" stroke="currentColor" strokeLinecap="square" strokeLinejoin="round"/>

    </Svg>
  )
}

export default Icon
