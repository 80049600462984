export const reactLocalStorage = {
  set(key: string, value: any) {
    localStorage[key] = value
    return localStorage[key]
  },
  get(key: string, defaultValue: any) {
    return localStorage[key] || defaultValue
  },
  setObject(key: string, value: any) {
    localStorage[key] = JSON.stringify(value)
    return localStorage[key]
  },
  getObject(key: string, value: any) {
    return JSON.parse(localStorage[key] || value)
  },
  removeKey(key: string) {
    localStorage.removeItem(key)
  },
  clear() {
    return localStorage.clear()
  },
}
export const reactSessionStorage = {
  set(key: string, value: any) {
    sessionStorage[key] = value
    return sessionStorage[key]
  },
  get(key: string, defaultValue: any) {
    return sessionStorage[key] || defaultValue
  },
  setObject(key: string, value: any) {
    sessionStorage[key] = JSON.stringify(value)
    return sessionStorage[key]
  },
  getObject(key: string, value: any) {
    return JSON.parse(sessionStorage[key] || value)
  },
  removeKey(key: string) {
    sessionStorage.removeItem(key)
  },
  clear() {
    return sessionStorage.clear()
  },
}

/** Delete item localStorage */
export const deleteLocalStorage = (name: string) => {
  return localStorage.getItem(name) ? localStorage.removeItem(name) : sessionStorage.removeItem(name)
}

/** Delete all localStorage */
export const cleanLocalStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
}
/** get value localstorage */
export const getLocalStorage = (name: string) => {
  return localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : JSON.parse(sessionStorage.getItem(name))
}
export const saveLocalStorage = ({ data, name, type }): any => {
  deleteLocalStorage(name)
  if (type === 'session') {
    sessionStorage.setItem(name, JSON.stringify(data))
  } else {
    localStorage.setItem(name, JSON.stringify(data))
  }
}
