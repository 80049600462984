import { STATUS_APPLY_JOB_CANDIDATE_MAP } from 'common/constants/codeConstant'
import { CheckCircle, XCircle } from 'phosphor-react'
import React from 'react'
import { useTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import { formatTimer } from 'utils/dateTimeFormat'
import * as S from './style'

interface Props extends React.AllHTMLAttributes<HTMLDivElement> {
  item?: any
  status: string
  onClick?: () => void
}

const ApplyNotification = ({ onClick, item, status }: Props) => {
  const theme: any = useTheme()
  const { t } = useTranslation('common')

  return (
    <S.ApplyLayout onClick={onClick} isRead={item?.isRead}>
      <div className="icon-status">
        {status === STATUS_APPLY_JOB_CANDIDATE_MAP['10ACCEPT'] && (
          <CheckCircle color={theme.colors.success400} size={32} />
        )}
        {status === STATUS_APPLY_JOB_CANDIDATE_MAP['80REJECT'] && <XCircle color={theme.colors.error500} size={32} />}
      </div>
      <div className="notification-content">
        <div className="message">{item?.message}</div>
        <div className="company">
          {t('candidate.companyName')}: {item?.metadata?.companyName}
        </div>
        <div className="status-wrap">
          <div className="status">
            {t('candidate.status')}:{' '}
            <span className={`accent ${status?.toLowerCase()}`}> {t(`STATUS_APPLY.${item?.metadata?.status}`)}</span>
          </div>
          <div className="time">{formatTimer(item?.createdAt, t)}</div>
        </div>
      </div>
    </S.ApplyLayout>
  )
}

export default ApplyNotification
