import { Dropdown, MenuProps } from 'antd'
import { YL_LANGUAGE } from 'common/constants/codeConstant'
import { CheckIcon, GlobalIcon } from 'components/Svg'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getLocalStorage, reactLocalStorage } from 'utils/localStorage'
import getKeyByValue from 'utils/getKeyByValue'

import * as S from './style'

interface Props {
  isActive: boolean
}

export const convertLang = {
  ko: 'ko',
  vi: 'vi',
}
export const LANG_MAP = {
  ko: '한',
  vi: 'vi',
}

const LanguageDropdown = ({ isActive = false }: Props) => {
  const { i18n } = useTranslation('common')
  const [active, setActive] = useState<string>(convertLang.vi)

  const setLangSelected = (e?: any) => {
    reactLocalStorage.set(YL_LANGUAGE, JSON.stringify(e?.key))
  }

  const handleMenuClick: MenuProps['onClick'] = (item: any) => {
    i18n?.changeLanguage(item?.key)
    setLangSelected(item)
    setActive(LANG_MAP[item?.key])
    window.location.reload()
  }

  useEffect(() => {
    const lang = getLocalStorage(YL_LANGUAGE) ?? 'vi'
    if (lang) {
      setActive(LANG_MAP[lang])
    }
  }, [])

  const items: MenuProps['items'] = [
    // {
    //   label: <div className="language-item">English</div>,
    //   key: 'en',
    //   icon: <CheckIcon width={10} color={active === 'en' ? '#807F96' : 'transparent'} />,
    // },
    {
      label: <div className="language-item">Tiếng Việt</div>,
      key: convertLang.vi,
      icon: (
        <CheckIcon
          width={10}
          color={
            getKeyByValue(LANG_MAP, active) === getKeyByValue(convertLang, convertLang.vi) ? '#807F96' : 'transparent'
          }
        />
      ),
    },
    {
      label: <div className="language-item">한국어</div>,
      key: convertLang.ko,
      icon: (
        <CheckIcon
          width={10}
          color={
            getKeyByValue(LANG_MAP, active) === getKeyByValue(convertLang, convertLang.ko) ? '#807F96' : 'transparent'
          }
        />
      ),
    },
  ]

  const menuProps = {
    items,
    className: 'languageList',
    onClick: handleMenuClick,
  }

  return (
    <S.LanguageWrapper>
      <Dropdown
        menu={menuProps}
        trigger={['hover']}
        placement="bottom"
        dropdownRender={(menu) => {
          return <S.LanguageList>{menu}</S.LanguageList>
        }}
      >
        <div className={`globalLanguage ${isActive ? 'textColor' : ''}`}>
          <GlobalIcon width={24} />
          <S.LabelLanguage>{active}</S.LabelLanguage>
        </div>
      </Dropdown>
    </S.LanguageWrapper>
  )
}

export default LanguageDropdown
