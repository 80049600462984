import { FolderOpen } from 'phosphor-react'
import React from 'react'
import { useTheme } from 'styled-components'

import { useTranslation } from 'react-i18next'
import * as S from './style'

const NoInformation = () => {
  const theme: any = useTheme()
  const { t } = useTranslation('common')
  return (
    <S.Container>
      <div>
        <FolderOpen size={32} />
      </div>
      <p>{t('noAnnouncement')}</p>
    </S.Container>
  )
}

export default NoInformation
