/* eslint-disable no-useless-catch */
import { AxiosResponse } from 'axios'
import axios from 'services/axiosInstance'
import { Skill } from 'types/tag'

const URL_GET_SKILLS = '/tags?type=SKILL'
const URL_GET_TITLES = '/tags?type=TITLE'

export const getAllSkill = async (): Promise<AxiosResponse<Skill[]>> => {
  try {
    const response: AxiosResponse = await axios()
      .get(URL_GET_SKILLS)
      .catch((error) => error.response)
    return response?.data
  } catch (error) {
    throw error
  }
}

export const getAllByTitle = async (): Promise<AxiosResponse<Skill[]>> => {
  try {
    const response: AxiosResponse = await axios()
      .get(URL_GET_TITLES)
      .catch((error) => error.response)
    if (response?.status === 200) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}
