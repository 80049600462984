import Footer from 'components/common/Footer'
import Header from 'components/common/Header'
import React from 'react'
import { Outlet } from 'react-router-dom'

function MainTemplate() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  )
}

export default MainTemplate
