/* eslint-disable no-param-reassign */

import axios, { AxiosInstance } from 'axios'
import { YL_CANDIDATE, YL_LANGUAGE } from 'common/constants/codeConstant'
import { HTTP_STATUS_CODE } from 'common/constants/httpsConstant'
import { ROUTES } from 'common/constants/routes'
import { cleanLocalStorage, deleteLocalStorage, getLocalStorage } from 'utils/localStorage'

const { REACT_APP_API_SERVER } = process.env

export const BASE_URL = REACT_APP_API_SERVER

const instance = () => {
  const headers: any = {
    'Content-Type': 'application/json',
  }
  //check token
  const author = getLocalStorage(YL_CANDIDATE)
  if (author?.accessToken) {
    headers.Authorization = `Bearer ${author?.accessToken}`
  }
  //check multiple language
  const language = getLocalStorage(YL_LANGUAGE) ? getLocalStorage(YL_LANGUAGE) : 'vi'
  headers['Accept-Language'] = language

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers,
  })

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.data) {
        const haveFile = Object.values(config.data).some((e: any) => e && e.toString() === '[object File]')
        if (haveFile) {
          config.headers['Content-Type'] = 'multipart/form-data'
        }
      }

      return config
    },
    (error) => Promise.reject(error),
  )

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.data) {
        if (error.response.status === 401 || error.response.status === 419) {
          deleteLocalStorage(YL_CANDIDATE)
          window.location.replace(`${ROUTES.LOGIN}?flashMessage=${HTTP_STATUS_CODE[error.response?.data?.errorCode]}`)
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ ...error.response.data })
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          error: true,
          message: 'Error Code 100: No response error from server',
          statusCode: error && error.request && error.request.status ? error.request.status : '1899',
        })
      }
      return Promise.reject(error.response)
    },
  )

  return axiosInstance
}

export default instance
