// Montserrat
export const MontserratBlack = require('./Montserrat-Black.ttf')
export const MontserratBlackItalic = require('./Montserrat-BlackItalic.ttf')
export const MontserratBold = require('./Montserrat-Bold.ttf')
export const MontserratBoldItalic = require('./Montserrat-BoldItalic.ttf')
export const MontserratExtraBold = require('./Montserrat-ExtraBold.ttf')
export const MontserratExtraBoldItalic = require('./Montserrat-ExtraBoldItalic.ttf')
export const MontserratExtraLight = require('./Montserrat-ExtraLight.ttf')
export const MontserratExtraLightItalic = require('./Montserrat-ExtraLightItalic.ttf')
export const MontserratItalic = require('./Montserrat-Italic.ttf')
export const MontserratLight = require('./Montserrat-Light.ttf')
export const MontserratLightItalic = require('./Montserrat-LightItalic.ttf')
export const MontserratMedium = require('./Montserrat-Medium.ttf')
export const MontserratMediumItalic = require('./Montserrat-MediumItalic.ttf')
export const MontserratRegular = require('./Montserrat-Regular.ttf')
export const MontserratSemiBold = require('./Montserrat-SemiBold.ttf')
export const MontserratSemiBoldItalic = require('./Montserrat-SemiBoldItalic.ttf')
export const MontserratThin = require('./Montserrat-Thin.ttf')
export const MontserratThinItalic = require('./Montserrat-ThinItalic.ttf')
export const SonoRegular = require('./Sono-Regular.ttf')
export const InterRegular = require('./Inter-Regular.ttf')
export const InterMedium = require('./Inter-Medium.ttf')
