import moment from 'moment-timezone'

export function timeFormat(date: any) {
  // let rawTime = new Date(date).toString()
  const format = moment(date).tz('Asia/Seoul').format('YYYY-MM-DD HH:mm:ss')
  // let format = moment(rawTime).format("YYYY-MM-DD HH:mm:ss");
  return format
}

export function calculateDayAGo(dayAGo: string) {
  const dayCurrent = new Date()
  const convertDay = new Date(dayAGo)
  const millisecondsBetweenTwoDays = dayCurrent.getTime() - convertDay.getTime()
  const totalDay = Math.floor(millisecondsBetweenTwoDays / (1000 * 60 * 60 * 24))

  return totalDay
}

export function formatTimer(time, t): string {
  const now = moment(new Date())
  const end = moment(time)
  const diffSeconds = now.diff(end, 'seconds')
  const diffMinutes = diffSeconds / 60
  const diffHours = diffMinutes / 60
  const diffDays = diffHours / 24

  if (diffMinutes < 1) {
    return t('justFinished')
  } else if (diffMinutes < 60) {
    return `${Math.floor(diffMinutes)}${t('minuteAgo')}`
  } else if (diffHours < 24) {
    return `${Math.floor(diffHours)}${t('hourAgo')}`
  } else {
    return `${Math.floor(diffDays)}${t('daysAgo')}`
  }
}
