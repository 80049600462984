import { theme } from 'styled-tools'
import styled from 'styled-components'
import { Button, Layout } from 'antd'
import { NavLink } from 'react-router-dom'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {}

export const StyledHeader = styled.header`
  background-color: transparent;
  height: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;

  color: ${(props) => props.theme.colors.white};
  &.home {
    position: absolute;
    &.scroll {
      /* position: sticky; */
      position: fixed;
    }
  }
  &.bgHeader {
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.primary600};
    box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  }
  @media (max-width: 768px) {
    height: 56px;
    z-index: 1001;
  }
`

export const Container = styled(Layout)`
  background-color: transparent;
  max-width: ${(props) => `${props.theme.maxWidth}px`};
  margin: 0 auto;
  .toggle-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
`

export const HeaderMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  gap: 40px;

  @media (max-width: 992px) {
    gap: 20px;
    padding: 0 16px;
  }
  @media (max-width: 768px) {
    justify-content: center;
    padding: 16px;
    height: 56px;
  }
`

export const NavBarLink = styled(NavLink)`
  text-decoration: none;
  /* color: ${(props) => props.theme.colors.white}; */
  color: inherit;
  font-family: ${(props) => props.theme.font.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  :hover {
    /* color: ${(props) => props.theme.colors.white}; */
    color: inherit;
    opacity: 0.65;
  }

  &.flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  h1 {
    /* color: ${(props) => props.theme.colors.white}; */
    font-family: ${(props) => props.theme.font.bold};
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
  }

  &.btn-register {
    border: 1px solid;
    border-color: inherit;
    padding: 6.5px 24px;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: #ffffff;
      color: #2b2a50;
      transition: all 0.3s ease-in-out;
    }
  }
  &.textColor {
    /* color: ${(props) => props.theme.colors.primary600}; */
  }
  &.textColor.btn-register {
    /* border: 1px solid ${(props) => props.theme.colors.primary600}; */
  }
  &.textColor h1 {
    color: ${(props) => props.theme.colors.primary600};
  }
  @media (max-width: 992px) {
    font-size: 14px;
    h1 {
      font-size: 28px;
    }
    &.btn-register {
      padding: 4.5px 20px;
    }
  }
  @media (max-width: 768px) {
    font-size: 12px;

    h1 {
      font-size: 24px;
    }
    &.btn-register {
      padding: 2.5px 14px;
    }
  }
`

export const LinkStyle = styled.a`
  text-decoration: none;
  /* color: ${(props) => props.theme.colors.white}; */
  color: inherit;
  font-family: ${(props) => props.theme.font.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  :hover {
    /* color: ${(props) => props.theme.colors.white}; */
    color: inherit;
    opacity: 0.65;
  }
  @media (max-width: 576px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    height: 40px;
    border: 1px solid ${(props) => props.theme.colors.secondary500};
    border-radius: 8px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 1;
    color: ${(props) => props.theme.colors.secondary500};
    font-family: ${(props) => props.theme.font.medium};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }
`

export const Logo = styled.div<Props>`
  background-color: #000000;
  background-image: url();
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 158px;
  height: 62px;

  @media (max-width: 768px) {
    min-width: 120px;
    height: 52px;
    background-size: contain;
  }
`

export const Box = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  height: 100%;
  @media (max-width: 992px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    width: 100vw;
  }
`
export const WrapNavLink = styled(Box)`
  gap: 40px;
  position: relative;
  .menu-all-job {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
`

export const ButtonStyle = styled(Button)`
  height: 40px;
  background-color: transparent;
  padding: 12px 16px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  line-height: 1;
  span {
    font-family: ${(props) => props.theme.font.medium};
    font-size: 16px;
    line-height: 16px;
    color: white;
  }
  &.accent {
    background-color: ${(props) => props.theme.colors.primary500};
    border-color: ${(props) => props.theme.colors.primary500};
    span {
      color: #ffffff;
    }
    :hover {
      color: ${(props) => props.theme.colors.primary500};
      border-color: ${(props) => props.theme.colors.primary500};
    }
  }

  @media (max-width: 768px) {
  }
`

export const WrapCandidate = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  img.user-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .ant-dropdown {
    color: red;
  }

  @media (max-width: 600px) {
    img.user-icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
`
export const BoxUser = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 1px;
  cursor: pointer;
  &.textColor .user-text {
    color: ${(props) => props.theme.colors.primary600};
  }
  .user-text {
    /* overflow: hidden; */
    padding: 0 2px;
    display: flex;
    align-items: center;
    gap: 2.5px;
    .user-name {
      font-family: ${(props) => props.theme.font.medium};
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      @media (max-width: 992px) {
        font-size: 14px;
      }
      @media (max-width: 768px) {
        //display: none;
        font-size: 16px;
        line-height: 24px;
        font-family: ${(props) => props.theme.font.semiBold};
      }
    }
    span {
      max-width: 79px;
      overflow: hidden;
      text-decoration: none;
      color: inherit;
      font-family: Montserrat-Medium;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
    }
  }
`

export const MenuDropdown = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 10px 15px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
  width: 230px;
`
export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 30px;
  gap: 26px;
  width: 100%;
  height: 48px;
  margin-top: auto;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral500};

  &.color-red {
    color: ${(props) => props.theme.colors.error500};
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    padding: 8px 0;
    justify-content: space-between;
    align-items: center;
    /* color: ${(props) => props.theme.colors.neutral700}; */
    color: ${(props) => props.theme.colors.neutral500};

    min-height: 40px;
    height: auto;

    &.color-red {
      padding: 0 16px;
      flex-direction: row-reverse;
      border: 1px solid ${(props) => props.theme.colors.error500};
      border-radius: 8px;
    }
    & > .menuItem {
      display: flex;
      align-items: center;
      gap: 12px;
      span {
        font-size: 14px;
        line-height: 20px;
        font-family: ${(props) => props.theme.font.medium};
        /* color: ${(props) => props.theme.colors.neutral700}; */
        color: ${(props) => props.theme.colors.neutral500};

        &.title {
          color: ${(props) => props.theme.colors.neutral900};
          font-family: ${(props) => props.theme.font.bold};
        }
      }
      &.submenu-item {
        padding-left: 20px;
      }
    }
  }
`
export const MobileLinkWrap = styled.div`
  display: flex;
  flex-direction: column;
`
export const MobileButtonLoginWrap = styled.div`
  margin-bottom: 16px;
`

export const MobileButtonLogin = styled(Button)`
  gap: 8px;
  width: 100%;
  height: 40px;
  background: ${(props) => props.theme.colors.primary500};
  border-radius: 8px;
  span {
    color: #fff;
    line-height: 1;
  }
`
export const TitleDrawer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: ${(props) => props.theme.font.semiBold};
  color: ${(props) => props.theme.colors.primary500};
`
export const NavLinkMobile = styled(NavLink)`
  font-family: ${(props) => props.theme.font.medium};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.neutral500};
  padding: 10px 16px;
`

export const MobileLine = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral200};
`

export const MenuItem = styled.div``

export const ViewAllWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 12px 16px;
  padding-right: 0;
  gap: 8px;
  & > span {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary500};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  svg {
    margin-top: 4px;
  }
`
