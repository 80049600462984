import { combineReducers } from '@reduxjs/toolkit'

import employeeCvSlice from 'redux/reducer/employeeWriteCv'
import recentSearches from './recentSearches'
import candidateReducer from './candidateReducer'

const rootReducer = combineReducers({ employeeCvSlice, recentSearches, candidateReducer })

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
