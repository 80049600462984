import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M4.6875 12H27.3125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.6875 20H27.3125" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M16 27.6742C18.7614 27.6742 21 22.4471 21 15.9992C21 9.55129 18.7614 4.32422 16 4.32422C13.2386 4.32422 11 9.55129 11 15.9992C11 22.4471 13.2386 27.6742 16 27.6742Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </Svg>
  )
}

export default Icon
