/* eslint-disable @typescript-eslint/ban-types */
import { Row } from 'antd'
import { ROUTES } from 'common/constants/routes'
import { CaretRight } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom'
import * as S from './styles'

type Props = {
  data?: any
  setMenuDropDownOpen: any
}
export const directToSearchPage = (navigate: NavigateFunction, query: string) => {
  navigate(`${ROUTES.JOB_ALL}?nameJob=${query}`)
}
const JobBySkill = ({ data, setMenuDropDownOpen }: Props) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  return (
    <S.ListJobContainer>
      <Row>
        {data &&
          data?.map((item: any) => (
            <S.Item key={item.id} onClick={() => directToSearchPage(navigate, item.name)}>
              {item.name}
            </S.Item>
          ))}
      </Row>
      <S.ViewAllWrap>
        <NavLink to={`${ROUTES.JOB_LIST}/jobsBySkill`} onClick={() => setMenuDropDownOpen(false)}>
          <span>
            {t('MENU_ALL_JOB.jobs_by_skill')} <CaretRight size={20} />
          </span>
        </NavLink>
      </S.ViewAllWrap>
    </S.ListJobContainer>
  )
}

export default JobBySkill
