import styled from 'styled-components'
import bgStar from 'assets/images/candidate/bgStar.png'

export const NotiLayout = styled.div`
  padding: 12px 53px 12px 24px;
  max-width: 370px;
  /* height: 94px; */
  left: 0px;
  background: #ffffff;
  border: 1px solid ${(props) => props.theme.colors.primary200};
  border-radius: 4px;
  .new-job {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0px;
    top: 0px;
    border-radius: 0px 4px;
    border-color: transparent;
    background-image: url('${bgStar}');

    @media (max-width: 768px) {
      width: 30px;
      height: 30px;
    }

    .star-job {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 7px;
      top: 7px;

      @media (max-width: 768px) {
        width: 8px;
        height: 8px;
        right: 4px;
        top: 4px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`
export const NotiContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
`

export const LogoWrap = styled.div`
  height: 70px;
  width: 70px;
  img {
    height: 100%;
    width: 100%;
  }
`
export const NotiMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .noti-message {
    color: ${props => props.theme.colors.primary600};
    font-family: ${props => props.theme.font.medium};
    font-size: 14px;
    line-height: 20px;
  }
  .read-more {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 170%;
    text-align: justify;
    text-decoration-line: underline;
    color: ${(props) => props.theme.colors.success500};
    cursor: pointer;

    @media (max-width: 768px) {
      font-size: 8px;
    }
  }
`
