/* eslint-disable no-useless-catch */
import { AxiosResponse } from 'axios'
import axios from 'services/axiosInstance'
import { CV } from 'types/cv'
import { EmployeeCv } from 'types/employeeCv'
import { PromiseResult } from 'types/promiseResult'

const URL_CREATE_CV = '/cvs'
const URL_GET_ALL_CV = '/cvs/my-cvs'
const URL_GET_ALL_APPLIED_JOB = '/applications/my-applications'

export const createCV = async (data: EmployeeCv) => {
  try {
    const response = await axios().post(URL_CREATE_CV, data)
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}

export const updateCvOnline = async (cvId: string, data: EmployeeCv) => {
  try {
    const response = await axios().patch(`${URL_CREATE_CV}/${cvId}`, data)
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}

interface CreateCvOffResult extends PromiseResult {
  data: any
}
export const createCVOffline = async (url: string, cvName: string): Promise<CreateCvOffResult> => {
  try {
    const response: AxiosResponse = await axios().post(URL_CREATE_CV, { cvURL: url, createdOnline: false, cvName })
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}

interface ResulGetAllCv {
  message: string
  result: boolean
  data: {
    hasNextPage: boolean
    hasPrevPage: boolean
    items: CV[]
    pageIndex: number
    pageSize: number
    totalItems: number
    totalPages: number
  }
}
export const getAllCv = async (pageIndex?: number, pageSize?: number): Promise<ResulGetAllCv> => {
  try {
    const response = await axios().post<ResulGetAllCv>(URL_GET_ALL_CV, { pageIndex, pageSize })
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error: any) {
    return error
  }
}

export const getAllAppliedJob = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios().post(URL_GET_ALL_APPLIED_JOB)
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error: any) {
    return error
  }
}

export interface Error {
  data: any
  errorCode: string
  message: string
  result: boolean
}

export const deleteCV = async (cvID: string): Promise<AxiosResponse> => {
  try {
    const response: AxiosResponse = await axios().delete(`${URL_CREATE_CV}/${cvID}`)
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}

export const getCvCreatedOnline = async (id: string): Promise<AxiosResponse> => {
  try {
    const response: AxiosResponse = await axios().get(`${URL_CREATE_CV}/${id}`)
    if (response?.data?.result) {
      return response?.data
    }
  } catch (error) {
    throw error
  }
}
