import bgFooter from 'assets/images/bgFooter.png'
import { ROUTES } from 'common/constants/routes'
import useWindowSize from 'hooks/useWindowSize'
import { CaretDown, CaretUp } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Logo from '../Logo'
import * as S from './Footer.style'

const Footer = () => {
  const { t } = useTranslation('common')

  const desktopRender = () => {
    return (
      <S.LinkWrapper>
        {/* <S.Link href="">{t('footer.value1')}</S.Link> */}
        <S.Link target="_blank" href={ROUTES.TERMS_CONDITIONS}>
          {t('footer.value2')}
        </S.Link>
        <S.Link target="_blank" href={ROUTES.TERMS_OF_SERVICE}>
          {t('footer.value3')}
        </S.Link>
        <S.Link target="_blank" href={ROUTES.PRIVACY_POLICY}>
          {t('footer.value4')}
        </S.Link>
        {/* <S.Link target="_blank" href="">
          {t('footer.value5')}
        </S.Link> */}
      </S.LinkWrapper>
    )
  }

  return (
    <S.StyledFooter bg={bgFooter}>
      <S.Container>
        <S.TitleFooter>
          <Logo dark />
          <h1>yLine</h1>
        </S.TitleFooter>
        <S.FooterWrapper>
          <S.ParagraphWrapper>
            <S.Paragraph>{t('footer.addressCompany')}</S.Paragraph>
          </S.ParagraphWrapper>
          {desktopRender()}
        </S.FooterWrapper>
        <S.BottomText>Copyright © yLine Corp. All rights reserved. All rights Reserved</S.BottomText>
      </S.Container>
    </S.StyledFooter>
  )
}

export default Footer
