import { YL_CANDIDATE } from 'common/constants/codeConstant'
import { reactLocalStorage, reactSessionStorage } from 'utils/localStorage'

export interface User {
  id?: string
  accessToken?: string
  email?: string
  fullName?: null
  phoneNumber?: string
  avatar?: string
  role?: string
  type?: string
  gender?: null
  dob?: null
  address?: null
  introduce?: null
  permissions?: string[]
}

function useAuthority() {
  const userLocalStorage: User = reactLocalStorage.getObject(YL_CANDIDATE, null)
  const userSessionStorage: User = reactSessionStorage.getObject(YL_CANDIDATE, null)
  const userAuthority = userLocalStorage || userSessionStorage

  return userAuthority
}

export default useAuthority
