import starImg from 'assets/images/candidate/star.png'
import { ArrowUpRight } from 'phosphor-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './style'

type Props = {
  item: any
  onClick?: () => void
}

const CompanyNotification = ({ item, onClick }: Props) => {
  const { t } = useTranslation('common')
  return (
    <S.NotiLayout onClick={onClick}>
      {item?.metadata?.isJobVip && (
        <div className="new-job">
          <div className="star-job">
            <img src={starImg} alt={starImg} />
          </div>
        </div>
      )}

      <S.NotiContent>
        <S.LogoWrap>
          <img src={item?.metadata?.companyLogo} alt={item?.metadata?.companyLogo} />
        </S.LogoWrap>
        <S.NotiMessage>
          <div className="noti-message">{item?.message}</div>
          <span className="read-more">
            {t('seeMore')} <ArrowUpRight size={16} />
          </span>
        </S.NotiMessage>
      </S.NotiContent>
    </S.NotiLayout>
  )
}

export default CompanyNotification
