import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 372px;
  gap: 10px;
  color: ${(props) => props.theme.colors.neutral300};
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.01em;
    color: ${(props) => props.theme.colors.neutral300};
    font-family: ${(props) => props.theme.font.medium};
  }
`
