import { useContext } from 'react'
import { useMatch } from 'react-router'
// eslint-disable-next-line camelcase
import { generatePath, matchPath, UNSAFE_RouteContext } from 'react-router-dom'

// const location = useLocation()

export const ADDRESS_SMART = ''
export const PARTNER_SITE = 'https://partner.yline.biz/'

export const ROUTES = {
  HOME: '/',
  JOB_ALL: '/job',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  COMPANIES: '/companies',
  REGISTER: '/register',
  USER: '/user',
  INVESTMENT: '/investment',
  COMMUNITY: '/community',
  ECOSYSTEM: '/ecosystem',
  EMPLOYER: '/employer',
  CANDIDATE: '/candidate',
  EMP_CREATE_CV: '/employee-create-cv',
  EMP_EDIT_CV: '/employee-edit-cv',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  TERMS_CONDITIONS: '/terms-conditions',
  JOB_LIST: '/list-type',
  IT_COMPANIES: '/ITCompanies',
  IT_NEWS: '/ITNews',
  IT_BLOG: '/ITBlog',
}

const hashId = '[0-9A-Fa-f]'
const hashText = '[A-Fa-f]'
const idSpecificPathRegExp = `${hashId}`

export const JOB_SLUG = 'prefixedJob'
export const JOB_CITY_SLUG = 'prefixedCity'
// export const YL_JOB_SLUG_ROUTE = `/:${JOB_SLUG}(${hashText})`
// export const YL_JOB_SLUG_ROUTE = `:${JOB_SLUG}`
// export const YL_JOB_CITY_SLUG_ROUTE = `:${JOB_CITY_SLUG}`

// export const YL_JOB_ROUTE = `${JOB_ALL}/${YL_JOB_SLUG_ROUTE}`
// export const YL_JOB_LANGUAGE_ROUTE = `${JOB_ALL}/${YL_JOB_SLUG_ROUTE}/${YL_JOB_CITY_SLUG_ROUTE}`
// export const YL_JOB_LANGUAGE_ROUTE = `${JOB_ALL}/:id`

export const ID_SLUG = 'prefixedID'
export const ID_ROUTE = `/:${ID_SLUG}`

export type RouteParams = { safeAddress: string }

export type RouteSlugs = {
  [JOB_SLUG]?: string
}

// export const useExtractPrefixed = (location: Location = window?.location, route = YL_JOB_LANGUAGE_ROUTE) => {
//   const pathname = location?.pathname
//   const search = location?.search
//   const match = matchPath(
//     {
//       path: route,
//     },
//     pathname,
//   )

//   const prefixedLanguage = match?.params?.[JOB_SLUG] || ''
//   const prefixedCity = match?.params?.[JOB_CITY_SLUG] || ''
//   return { prefixedLanguage, prefixedCity, search }
// }

// export const generateJobRoute = (path, paramsLanguage: any, paramsCity?: any) => {
//   const route = generatePath(path, {
//     [JOB_SLUG]: paramsLanguage,
//     [JOB_CITY_SLUG]: paramsCity,
//   })
//   return route
// }
