import { CaretRight } from 'phosphor-react'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import JobByCity from './JobByCity'
import JobByCompany from './JobByCompany'
import JobBySkill from './JobBySkill'
import JobByTitle from './JobByTitle'
import * as S from './styles'

type Props = { data: any; setMenuDropDownOpen: any }

const MenuAllJob = ({ data, setMenuDropDownOpen }: Props) => {
  const { t } = useTranslation('common')
  const [isHovering, setIsHovering] = useState<0 | 1 | 2 | 3>(0)
  const menus = [
    { title: t('MENU_ALL_JOB.jobs_by_skill'), id: 0 },
    { title: t('MENU_ALL_JOB.jobs_by_title'), id: 1 },
    { title: t('MENU_ALL_JOB.jobs_by_company'), id: 2 },
    { title: t('MENU_ALL_JOB.jobs_by_city'), id: 3 },
  ]

  const listJobBy = [
    {
      id: 0,
      component: (
        <JobBySkill
          data={data?.jobsBySkill?.filter((item, index) => index >= 0 && index < 32)}
          setMenuDropDownOpen={setMenuDropDownOpen}
        />
      ),
    },
    {
      id: 1,
      component: <JobByTitle data={data?.jobsByTitle} setMenuDropDownOpen={setMenuDropDownOpen} />,
    },
    {
      id: 2,
      component: <JobByCompany data={data?.jobsByCompany} setMenuDropDownOpen={setMenuDropDownOpen} />,
    },
    {
      id: 3,
      component: <JobByCity />,
    },
  ]

  const handleOnMouseOver = (id: any) => {
    setIsHovering(id)
  }

  const menuTitleRender = () => {
    return menus.map((item) => (
      <S.MenuTitle
        key={item.id}
        className={isHovering === item.id && 'hovering'}
        onMouseOver={() => handleOnMouseOver(item.id)}
      >
        {item.title} <CaretRight size={20} />
      </S.MenuTitle>
    ))
  }

  const renderContent = () => {
    return listJobBy.map((item) => {
      return <React.Fragment key={item.id}>{item.id === isHovering && item.component}</React.Fragment>
    })
  }

  return (
    <S.MenuContainer>
      <S.Wrapper>
        <S.MenuWrap>{menuTitleRender()}</S.MenuWrap>
        {renderContent()}
      </S.Wrapper>
    </S.MenuContainer>
  )
}

export default MenuAllJob
