/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Dropdown, MenuProps } from 'antd'
import Bell from 'assets/images/notification/Bell.svg'
import BellWhite from 'assets/images/notification/Bell_white.svg'
import { STATUS_APPLY_JOB_CANDIDATE_MAP, YL_LANGUAGE } from 'common/constants/codeConstant'
import { ROUTES } from 'common/constants/routes'
import { Text } from 'common/style/DefaultStyled'
import useAuthority from 'hooks/useAuthority'
import useIsMobile from 'hooks/useIsMobile'
import { Bell as BellMobile, CaretRight } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from 'services/axiosInstance'
import { getNotifications, markNotification } from 'services/notifications'
import { getLocalStorage } from 'utils/localStorage'
import ApplyNotification from './ApplyNotification'
import CompanyNotification from './CompanyNotification'
import NoInformation from './NoInformation'
import * as S from './style'

type Props = {
  count?: number
  isActive: boolean
  isScroll: boolean
}
const NotificationBell = ({ isScroll, count, isActive }: Props) => {
  const { t } = useTranslation('common')
  const isMobile = useIsMobile()
  return (
    <S.BadgeStyle isMobile={isMobile} size={isMobile ? 'small' : 'default'} count={count}>
      {isMobile ? (
        <div className="notification">
          <div className="bell-icon">
            <BellMobile size={24} />
          </div>
          <div>{t('header.notification')}</div>
          <div className="icon">
            <CaretRight size={16} />
          </div>
        </div>
      ) : (
        <img src={isActive || isScroll ? Bell : BellWhite} alt="" />
      )}
    </S.BadgeStyle>
  )
}

const Notifications = ({ isActive, isScroll }: Props) => {
  const { t } = useTranslation('common')
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()
  const [dataNotifications, setDataNotification] = useState<any>(null)
  const [totalNotiUnread, setTotalNotiUnread] = useState(0)

  const navigate = useNavigate()
  const userAuthority = useAuthority()
  const language = getLocalStorage(YL_LANGUAGE) ?? 'vi'

  const [eventSource, setEventSource] = useState(null)

  useEffect(() => {
    const initializeEventSource = () => {
      const url = `${BASE_URL}/notifications/subscribe?authorization=Bearer ${userAuthority?.accessToken}&locale=${language}`
      const newEventSource = new EventSource(url)

      newEventSource.onopen = () => {
        console.log(t('reconnect'))
      }

      newEventSource.onmessage = (event) => {
        fetchDataNotifications()
      }

      newEventSource.onerror = (error) => {
        setTimeout(() => {
          reconnect()
        }, 3000)
      }

      setEventSource(newEventSource)
    }

    const reconnect = () => {
      eventSource && eventSource.close()
    }

    initializeEventSource()

    return () => {
      eventSource && eventSource.close()
    }
  }, [])

  /**
   * Mark notification
   * @param idNoti id notification
   */
  const handleMarkNoti = async (idNoti: string, isRead: boolean) => {
    if (!isRead) {
      markNotification(idNoti).then((res) => {
        fetchDataNotifications()
      })
    }
  }

  const fetchDataNotifications = async () => {
    const resp: any = await getNotifications()
    let badgeNotifications = 0
    if (resp?.data.items.length > 0) {
      const notices = resp?.data.items.map((item: any) => {
        if (!item?.isRead) badgeNotifications++
        if (item?.templateId === 'APPLICATION_STATUS') {
          return {
            key: item?.id,
            label: (
              <ApplyNotification
                onClick={() => handleMarkNoti(item?.id, item?.isRead)}
                item={item}
                status={STATUS_APPLY_JOB_CANDIDATE_MAP[item?.metadata?.status]}
              />
            ),
          }
        } else {
          return {
            key: item?.id,
            label: (
              <CompanyNotification
                item={item}
                onClick={() => {
                  handleMarkNoti(item?.id, item?.isRead)
                  navigate(`${ROUTES.EMPLOYER}/${item?.metadata?.companyNameCode}`)
                }}
              />
            ),
          }
        }
      })
      setTotalNotiUnread(badgeNotifications)
      setDataNotification(notices)
    } else {
      const noInfo = [
        {
          key: 1,
          label: <NoInformation />,
        },
      ]
      setDataNotification(noInfo)
    }
  }

  const items: MenuProps['items'] = [
    {
      label: (
        <Text fontSize={16} lineHeight="24px" isSemiBold fontWeight={600} color="black">
          {t('header.notification')}
        </Text>
      ),
      key: '0',
      type: 'group',
      children: dataNotifications,
    },
  ]

  /**
   * OPEN DROPDOWN
   * @param flag
   */
  const handleOpenChange = (flag: boolean) => {
    setOpen(flag)
  }

  return (
    <Dropdown
      overlayStyle={{ zIndex: 1001 }}
      menu={{ items }}
      trigger={isMobile ? ['click'] : ['hover']}
      placement="bottomRight"
      open={open}
      onOpenChange={handleOpenChange}
      dropdownRender={(menu) => <S.NotificationList>{menu}</S.NotificationList>}
    >
      <S.NotiWrap>
        <NotificationBell isScroll={isScroll} isActive={isActive} count={totalNotiUnread} />
      </S.NotiWrap>
    </Dropdown>
  )
}

export default Notifications
