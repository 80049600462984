import axios from 'services/axiosInstance'
import { PromiseResult } from 'types/promiseResult'

const URL_GET_NOTIFICATIONS = '/notifications/my-notifications'
const URL_MARK_NOTI = "notifications/mark-as-read"

// ------------------------ ACTION -----------------------------------------
export const getNotifications = async (): Promise<PromiseResult | undefined> => {
  try {
    const response = await axios()
      .get(URL_GET_NOTIFICATIONS)
      .catch((error) => error.response)
    return response?.data
  } catch (error: any) {
    return error
  }
}


export const markNotification = async (idNoti: string) => {
  try {
    const response = await axios()
      .post(`${URL_MARK_NOTI}/${idNoti}`)
      .catch((error) => error.response)
    return response?.data
  } catch (error: any) {
    return error
  }
}