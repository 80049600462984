import useAuthority from 'hooks/useAuthority'
import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

interface Props {
  redirectPath?: string
}

const ProtectedRoute = ({ redirectPath }: Props) => {
  const userAuthority = useAuthority()
  return userAuthority ? <Outlet /> : <Navigate to={redirectPath} replace />
}

ProtectedRoute.defaultProps = {
  redirectPath: '/login',
}

export default ProtectedRoute
