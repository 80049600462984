import { Badge, Dropdown } from 'antd'
import exp from 'constants'
import styled from 'styled-components'

export const NotificationList = styled.div`
  .ant-dropdown-menu {
    border-radius: 4px;
    /* background: #f5f5f5; */
    box-shadow: 0px 10px 15px rgba(16, 24, 40, 0.1);
  }
  .ant-dropdown-menu {
    padding: 38px 24px 32px;
    .ant-dropdown-menu-item-group-title,
    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
      padding: 0;
    }
    .ant-dropdown-menu-item-group {
      max-height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    .ant-dropdown-menu-item-group-title {
      margin-bottom: 12px;
    }
    .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .ant-dropdown-menu .ant-dropdown-menu-item-group-list {
    margin: 0;
  }

  @media (max-width: 768px) {
    margin-top: -120px;
    margin-left: -16px;

    .ant-dropdown-menu {
      padding: 16px;
    }
  }
`
export const BadgeStyle = styled(Badge)<{ isMobile?: boolean }>`
  cursor: pointer;
  width: ${(props) => (props.isMobile ? '100%' : '24px')};
  .ant-scroll-number.ant-badge-count {
    top: 5px;
    inset-inline-end: 5px;
    /* background: ${(props) => props.theme.colors.error600}; */
  }

  @media (max-width: 768px) {
    .bell-icon {
      height: 24px;
      width: 24px;
    }
    .notification {
      position: relative;
      display: flex;
      align-items: center;
      position: relative;
      gap: 12px;
      div {
        /* margin-left: 12px; */
        /* position: absolute; */
        /* left: 38px; */
        font-size: 14px;
        line-height: 20px;
        font-family: ${(props) => props.theme.font.medium};
        color: ${(props) => props.theme.colors.neutral700};
        /* width: 100%; */
      }
      .icon {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        width: 16px;
      }
    }
  }
`

export const NotiWrap = styled.div`
  /* width: 100%; */
  flex: 1;
`
