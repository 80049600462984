import styled from 'styled-components'

export const ApplyLayout = styled.div<{isRead:boolean}>`
  padding: 12px 24px;
  max-width: 370px;
  background: ${(props)=>props.isRead ? "#ffffff" : props.theme.colors.neutral100};
  border: 1px solid ${(props) => props.theme.colors.primary200};
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  .notification-content {
    flex-grow: 1;
    .message,
    .company,
    .status,
    .accent {
      font-family: ${(props) => props.theme.font.medium};
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.primary600};
    }
  }

  .status-wrap {
    display: flex;
    justify-content: space-between;
    .accepted {
      color: ${(props) => props.theme.colors.success400};
    }
    .eliminated {
      color: ${(props) => props.theme.colors.error500};
    }
  }
  .time {
    font-style: italic;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;
    color: ${(props) => props.theme.colors.primary600};
  }


`
