import { createSlice } from '@reduxjs/toolkit'
import { YL_CANDIDATE } from 'common/constants/codeConstant'
import { deleteLocalStorage, getLocalStorage, saveLocalStorage } from 'utils/localStorage'

type Candidate = {
  accessToken: string
  avatar: string
  fullName: string
  email?: string
  remember?: boolean
}

const author = getLocalStorage(YL_CANDIDATE)

const dataCandidate: Candidate = {
  accessToken: author?.accessToken,
  avatar: author?.avatar,
  fullName: author?.fullName,
  email: author?.email,
  remember: author?.remember,
}

const candidateReducer = createSlice({
  name: 'candidateReducer',
  initialState: dataCandidate,
  reducers: {
    login: (state, action: { payload: Candidate; type }) => {
      const { remember, ...payload } = action.payload
      saveLocalStorage({
        data: action.payload,
        name: YL_CANDIDATE,
        type: remember === true ? 'localStorage' : 'session',
      })

      return { ...state, ...action.payload }
    },
    loginWithGoogle: (state, action: { payload: Candidate; type }) => {
      saveLocalStorage({
        data: action.payload,
        name: YL_CANDIDATE,
        type: 'localStorage',
      })

      return { ...state, ...action.payload }
    },
    update: (state, action) => {
      const { avatar, fullName, ...rest } = action.payload
      const stateConvert = { ...state, avatar, fullName }
      saveLocalStorage({
        data: stateConvert,
        name: YL_CANDIDATE,
        type: state.remember === true ? 'localStorage' : 'session',
      })

      return stateConvert
    },
    logout: (state, action) => {
      deleteLocalStorage(YL_CANDIDATE)
      return { ...action.payload }
    },
  },
})
export const { login, loginWithGoogle, update, logout } = candidateReducer.actions
export default candidateReducer.reducer
