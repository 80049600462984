import { useState, useEffect } from "react";

const getIsMobile = () => window.innerWidth <= 768;

function useIsMobile() {
    const [isMobile, setIsMobile] = useState<boolean>(getIsMobile());
    
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(getIsMobile());
        }

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isMobile;
}

export default useIsMobile;