import { createSlice } from '@reduxjs/toolkit'

const recentSearches = []

const recentSearchesSlice = createSlice({
  name: 'recentSearches',
  initialState: recentSearches,
  reducers: {
    addSearch: (state, action) => {
      const newSearches = [action.payload, ...state.filter((s) => s !== action.payload)].slice(0, 5)
      return newSearches
    },
  },
})
export const { addSearch } = recentSearchesSlice.actions
export default recentSearchesSlice.reducer
