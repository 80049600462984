import 'antd/dist/reset.css'
import React, { lazy, useEffect } from 'react'
/** Untils */
import GlobalStyles from 'common/style/GlobalStyle'
import Normalize from 'common/style/Normalize'
import defaultTheme from 'common/style/themes/default'
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'
/** Router Global */

import { ROUTES } from 'common/constants/routes'

import { YL_LANGUAGE } from 'common/constants/codeConstant'
import { convertLang } from 'components/common/LanguageDropdown'
import { useTranslation } from 'react-i18next'
import ProtectedRoute from 'routes'
import { ThemeProvider } from 'styled-components'
import { MainTemplate } from 'templates'
import { getLocalStorage } from 'utils/localStorage'
import ScrollToTop from 'utils/ScrollToTop'
import { HelmetProvider } from 'react-helmet-async'

/** Page */
const PageNotFound = lazy(() => import('pages/PageNotFound'))
const Home = lazy(() => import('pages/Home'))
const Employer = lazy(() => import('pages/Employer'))
const Register = lazy(() => import('pages/Register'))
const FormSignUp = lazy(() => import('components/register/FormSignUp'))
const FormSignIn = lazy(() => import('components/register/FormSignIn'))
const FormForgotPassword = lazy(() => import('components/register/FormForgotPassword'))
const FormResetPassword = lazy(() => import('components/register/FormResetPassword'))
const ITCompanies = lazy(() => import('pages/ITCompanies'))
const ITNew = lazy(() => import('pages/ITNew'))
const ITNewDetail = lazy(() => import('pages/ITNewDetail'))
const ITBlog = lazy(() => import('pages/ITBlog'))
const ITBlogDetail = lazy(() => import('pages/ITBlogDetail'))
const NewBlog = lazy(() => import('pages/NewBlog'))
const EditBlog = lazy(() => import('pages/EditBlog'))

const WriteCV = lazy(() => import('pages/WriteCV'))
const JobDetail = lazy(() => import('pages/JobDetail'))
const Candidate = lazy(() => import('pages/Candidate'))
const JobAll = lazy(() => import('pages/Job'))
const LoginGoogle = lazy(() => import('pages/LoginGoogle'))
const JobList = lazy(() => import('components/common/Header/MenuAllJob/AllJobs'))
const CompaniesByTitle = lazy(() => import('pages/CompaniesByTitle'))
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'))
const TermsConditions = lazy(() => import('pages/TermsConditions'))
const TermsOfService = lazy(() => import('pages/TermsOfService'))

//TODO: Will be uploaded in the future
const PageUpdating = lazy(() => import('pages/PageUpdating'))

const App: React.FC = () => {
  const { i18n } = useTranslation('common')
  // Dummy state for forcing rerender of App component
  const [counter, setCounter] = React.useState(0)

  useEffect(() => {
    const lang = getLocalStorage(YL_LANGUAGE) ?? 'vi'
    i18n?.changeLanguage(lang ?? convertLang.vi)
  }, [])

  useEffect(() => {
    i18n.on('languageChanged', () => {
      setCounter((prev) => prev + 1)
    })
  }, [i18n])

  return (
    <ThemeProvider theme={defaultTheme}>
      <Normalize />
      <GlobalStyles />
      <Router>
        <HelmetProvider>
          <ScrollToTop />
          <Routes>
            <Route path="" element={<MainTemplate />}>
              <Route index element={<Home />} />
              <Route path={`${ROUTES.JOB_ALL}/:titleCode`} element={<JobDetail />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path={`${ROUTES.EMPLOYER}/:nameCode`} element={<Employer />} />
              <Route path={`${ROUTES.JOB_ALL}/*`} element={<JobAll />} />
              <Route path={`${ROUTES.JOB_LIST}/:listType`} element={<JobList />} />
              <Route path={`${ROUTES.COMPANIES}/*`} element={<CompaniesByTitle />} />
              <Route path="google/callback" element={<LoginGoogle />} />

              {/* TODO:*/}
              <Route path={ROUTES.IT_COMPANIES} element={<ITCompanies />} />
              <Route path={ROUTES.IT_NEWS} element={<ITNew />} />
              <Route path={`${ROUTES.IT_NEWS}/:idITNew`} element={<ITNewDetail />} />
              <Route path={`${ROUTES.IT_BLOG}`} element={<ITBlog />} />
              <Route path={`${ROUTES.IT_BLOG}/tag/:tagId`} element={<ITBlog />} />
              <Route path={`${ROUTES.IT_BLOG}/:idBlog`} element={<ITBlogDetail />} />
              {/* footer*/}

              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={ROUTES.TERMS_CONDITIONS} element={<TermsConditions />} />
              <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsOfService />} />
              {/* Route for candidate */}
              <Route element={<ProtectedRoute />}>
                <Route path={`${ROUTES.EMP_CREATE_CV}`} element={<WriteCV />} />
                <Route path={`${ROUTES.EMP_EDIT_CV}/:id`} element={<WriteCV isEditing />} />
                <Route path={ROUTES.CANDIDATE} element={<Candidate />} />
                <Route path={`${ROUTES.CANDIDATE}/new-blog`} element={<NewBlog />} />
                <Route path={`${ROUTES.CANDIDATE}/edit-blog/:idBlog`} element={<EditBlog />} />
              </Route>
            </Route>
            {/* Route login, logout */}
            <Route element={<Register />}>
              <Route path={ROUTES.REGISTER} element={<FormSignUp />} />
              <Route path={ROUTES.FORGOT_PASSWORD} element={<FormForgotPassword />} />
              <Route path={ROUTES.RESET_PASSWORD} element={<FormResetPassword />} />
              <Route path={ROUTES.LOGIN} element={<FormSignIn />} />
            </Route>
          </Routes>
        </HelmetProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
