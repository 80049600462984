import styled from 'styled-components'

export const ListJobContainer = styled.div`
  padding: 12px 16px;
  border-left: 1px solid ${(props) => props.theme.colors.neutral300};
  width: 100%;
  flex: 1;
`

export const ViewAllWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 12px 16px;
  padding-right: 0;
  gap: 8px;
  & > span {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    color: ${(props) => props.theme.colors.secondary500};
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  svg {
    margin-top: 4px;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`
export const Item = styled.div`
  padding: 12px;
  padding-left: 22px;
  width: 50%;
  font-family: ${(props) => props.theme.font.medium};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.neutral500};
  :hover {
    text-decoration-line: underline;
  }
`
