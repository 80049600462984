import styled from 'styled-components'

export const MenuContainer = styled.div`
  position: absolute;
  top: 90%;
  left: -20%;
  background: #ffffff;
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
  border-radius: 4px;
  max-width: 1092px;
  min-width: 500px;
  width: 100vw;
  z-index: 1051;
`
export const Wrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: stretch;
`

export const MenuWrap = styled.div`
  padding: 12px 16px;
  width: 275px;
  min-width: 230px;
`

export const ContentWrap = styled.div`
  padding: 12px 16px;
  border-left: 1px solid ${(props) => props.theme.colors.secondary100};
`
export const MenuTitle = styled.div`
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;

  :hover {
    background-color: ${(props) => props.theme.colors.secondary500};
    color: #ffffff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  &.hovering {
    background-color: ${(props) => props.theme.colors.secondary500};
    cursor: pointer;
    color: #ffffff;
    transition: all 0.3s ease;
  }
`
