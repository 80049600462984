import styled from 'styled-components'

export const ListJobContainer = styled.div`
  padding: 12px 16px;
  border-left: 1px solid ${(props) => props.theme.colors.neutral300};
  width: 100%;
  flex: 1;

  @media (max-width: 1200px) {
    overflow-y: auto;
    max-height: 500px;

    &::-webkit-scrollbar {
      width: 7px;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${(props) => props.theme.colors.primary50};
      -webkit-box-shadow: none;
      border-radius: 7px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: transparent;
      background-color: transparent;
    }
  }
`

export const ViewAllWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 12px 16px;
  padding-right: 0;
  gap: 8px;
  span {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    font-family: ${(props) => props.theme.font.medium};
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: -0.01em;
    color: ${(props) => props.theme.colors.secondary500};
    text-underline-offset: 3px;
    :hover {
      text-decoration: underline;
    }
  }
  svg {
    margin-top: 4px;
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
`
export const Item = styled.div`
  padding: 12px;
  padding-left: 22px;
  width: 25%;
  font-family: ${(props) => props.theme.font.medium};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.neutral500};
  :hover {
    text-decoration-line: underline;
  }

  @media (max-width: 1200px) {
    width: 50%;
  }
`
