/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RcFile } from 'antd/es/upload'
import { nanoid } from 'nanoid'
import { getCvCreatedOnline } from 'services/cv'
import { WorkExp } from 'types/cv'
import { Education, EmployeeCv, ExperienceWork } from 'types/employeeCv'
import { Skill } from 'types/tag'

interface EmployeeCVState {
  avatar: RcFile
  employeeCv: EmployeeCv
  editingExperience: ExperienceWork | null
  editingEducation: Education | null
  errorMesssage: string | null
  isAvatarEdited: boolean
}

const initialState: EmployeeCVState = {
  avatar: null,
  employeeCv: {
    fullName: '',
    email: '',
    phone: '',
    cvName: '',
    position: '',
    about: '',
    avatar: '',
    workExp: [],
    gender: '',
    dob: '',
    address: '',
    skills: [],
    education: [],
    createdOnline: true,
  },
  editingExperience: null,
  editingEducation: null,
  isAvatarEdited: false,
  errorMesssage: null,
}

const employeeCvSlice = createSlice({
  name: 'employeeCvSlice',
  initialState,
  reducers: {
    //---------------------------------- WORK EXPERIENCE OF CV ----------------------------------
    addExperience: (state, action: PayloadAction<ExperienceWork>) => {
      state.employeeCv.workExp.push({ ...action.payload, id: nanoid() })
    },
    getExperience: (state, action: PayloadAction<WorkExp>) => {
      state.editingExperience = action.payload
    },
    updateExperience: (state, action: PayloadAction<WorkExp>) => {
      state.employeeCv.workExp.some((item, index) => {
        if (item.id === action.payload.id) {
          state.employeeCv.workExp[index] = action.payload
          return true
        }
        return false
      })
      state.editingExperience = null
    },
    cancelUpdateExperience: (state, action: PayloadAction<ExperienceWork>) => {
      state.editingExperience = null
    },
    deleteExperience: (state, action: PayloadAction<ExperienceWork>) => {
      state.employeeCv.workExp = state.employeeCv.workExp.filter((item) => item.id !== action.payload.id)
      state.editingExperience = null
    },
    //------------------------------------- EDUCATION OF CV ---------------------------------------
    addEducation: (state, action: PayloadAction<Education>) => {
      state.employeeCv.education.push({ ...action.payload, id: nanoid() })
    },
    getEducation: (state, action: PayloadAction<Education>) => {
      state.editingEducation = action.payload
    },
    updateEducation: (state, action: PayloadAction<Education>) => {
      state.employeeCv.education.some((item, index) => {
        if (item.id === action.payload.id) {
          state.employeeCv.education[index] = action.payload
          return true
        }
        return false
      })
      state.editingEducation = null
    },
    cancelUpdateEducation: (state, action: PayloadAction<Education>) => {
      state.editingEducation = null
    },
    deleteEducation: (state, action: PayloadAction<Education>) => {
      state.employeeCv.education = state.employeeCv.education.filter((item) => item.id !== action.payload.id)
      state.editingEducation = null
    },
    //------------------------------------- AVATAR OF CV  ---------------------------------------
    setAvatar: (state, action: PayloadAction<string>) => {
      state.employeeCv.avatar = action.payload
    },
    //------------------------------------- SKILLS CV  ---------------------------------------
    addSkill: (state, action: PayloadAction<Skill[]>) => {
      // state.employeeCv.skills.push(action.payload)
      state.employeeCv.skills = action.payload
    },
    removeSkill: (state, action: PayloadAction<Skill[]>) => {
      state.employeeCv.skills = action.payload
      // const skillIndex = state.employeeCv.skills.findIndex((skill) => skill.id === action.payload.id)
      // state.employeeCv.skills = [...state.employeeCv.skills.filter((item, index) => index !== skillIndex)]
    },
    clearCV: (state) => {
      state.avatar = null
      state.employeeCv = {
        fullName: '',
        email: '',
        phone: '',
        cvName: '',
        position: '',
        about: '',
        avatar: '',
        workExp: [],
        gender: '',
        dob: '',
        address: '',
        skills: [],
        education: [],
        createdOnline: true,
      }
      state.isAvatarEdited = false
    },
    uploadAvatar: (state, action: PayloadAction<RcFile>) => {
      state.avatar = action.payload
    },
    editedAvatar: (state, action: PayloadAction<boolean>) => {
      state.isAvatarEdited = true
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchCvCreateOnlineById.fulfilled, (state, action) => {
      state.employeeCv = action.payload.data
      state.avatar = action.payload.data?.avatar
    })
    builder.addCase(fetchCvCreateOnlineById.rejected, (state, action) => {
      state.errorMesssage = action.error.message
    })
  },
})

export const fetchCvCreateOnlineById = createAsyncThunk('cvs/getCv', async (id: string) => {
  const response = getCvCreatedOnline(id)
  return response
})

export const {
  addExperience,
  getExperience,
  updateExperience,
  cancelUpdateExperience,
  deleteExperience,
  addEducation,
  cancelUpdateEducation,
  deleteEducation,
  getEducation,
  updateEducation,
  setAvatar,
  addSkill,
  removeSkill,
  clearCV,
  uploadAvatar,
  editedAvatar,
} = employeeCvSlice.actions

export default employeeCvSlice.reducer
