import { convertLang } from 'components/common/LanguageDropdown'
import Loading from 'components/common/Loading'
import i18next from 'i18next'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import { I18nextProvider } from 'react-i18next'
import krTranslation from 'translations/kr/common.json'
import vnTranslation from 'translations/vn/common.json'
import App from './App'
import Providers from './Providers'
import * as serviceWorker from './serviceWorker'

const tagManagerArgs = {
  gtmId: 'GTM-PJ9W7NZ',
  dataLayerName: 'PageDataLayer',
}

TagManager.initialize(tagManagerArgs)

function getLoading() {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setInterval(() => {}, 2000)
  return <Loading />
}

i18next.init({
  fallbackLng: convertLang.vi,
  interpolation: { escapeValue: false }, // React already does escaping
  lng: convertLang.vi, // language default to use
  resources: {
    [convertLang.ko]: {
      common: krTranslation,
    },
    // en: {
    //   common: common_en, // 'common' is our custom namespace
    // },
    [convertLang.vi]: {
      common: vnTranslation, // 'common' is our custom namespace
    },
  },
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Providers>
        <Suspense fallback={getLoading()}>
          <App />
        </Suspense>
      </Providers>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
