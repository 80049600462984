import styled from 'styled-components'

export const LanguageWrapper = styled.div`
  border-color: inherit;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  .globalLanguage {
    display: flex;
    align-items: center;
    &:hover {
      transition: 0.3s all ease-in-out;
      color: inherit;
      opacity: 0.65;
    }
  }

  .globalLanguage.textColor {
    color: ${(props) => props.theme.colors.primary600};
  }
`
export const LanguageList = styled.div`
  .languageList {
    /* display: flex; */
    padding: 4px 0;
    border-radius: 4px;
    overflow: hidden;
    transform: translateY(14px) translateX(-10px);
    .ant-dropdown-menu-item {
      padding: 6px 16px;
      transition: all 0.3s ease-in-out;
      border-radius: 0;
      &:hover {
        background-color: #f4f4f6;
      }
    }
    .ant-dropdown-menu-item-icon {
      margin-right: 12px;
    }
  }
  .language-item {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.primary600};
  }
`

export const LabelLanguage = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  margin-left: 12px;
  text-transform: uppercase;
  width: 25px;
  font-family: ${(props) => props.theme.font.medium};
`
