export const HTTP_STATUS_CODE = {
  AUTH_404: 'This email does not exist',
  AUTH_400: 'The email has already existed',
  AUTH_PW_400: 'The incorrect password',
  AUTH_EMAIL_404: "The employee's email does not exist",
  USER_400: 'This email is already used for signing up with Email',
  AUTH_WRONG_NORMAL_400: 'This email is already used for signing up with Google',
  AUTH_401: 'HTTP_STATUS.AUTH_401',
  JOB_404: "The job doesn't exist",
  USER_PW_404: 'The old password is not correct',
  AUTH_TOKEN_400: 'Expired or used token',
}
