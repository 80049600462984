import React, { CSSProperties, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
  height?: number
  width?: number
  color?: string
  style?: CSSProperties
  dark?: boolean
}

const Logo = (props: Props) => {
  const { height, width, style, color, dark } = props
  return (
    <svg style={style} width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7056_206635)">
        <path
          d="M16 32C7.16333 32 0 24.8367 0 16C0 7.16333 7.16333 0 16 0C24.8367 0 32 7.16333 32 16C32 24.8367 24.8367 32 16 32Z"
          fill={dark ? 'white' : '#03045E'}
        />
        <path
          d="M13.0883 23.7667H10.0517C9.70667 23.7667 9.46333 23.4267 9.575 23.1L13.3317 12.1067C13.4017 11.9033 13.5933 11.7667 13.8083 11.7667H16.8433C17.1883 11.7667 17.4317 12.1067 17.32 12.4333L13.5633 23.4267C13.495 23.6283 13.3033 23.7667 13.0883 23.7667Z"
          fill={dark ? '#00B4D8' : 'white'}
        />
        <path
          d="M25.85 16.4967L24.805 19.5567C24.735 19.76 24.5433 19.8967 24.3283 19.8967H21.2933C20.9483 19.8967 20.705 19.5567 20.8167 19.23L21.8617 16.17C21.9317 15.9667 22.1233 15.83 22.3383 15.83H25.3733C25.7183 15.83 25.9617 16.17 25.85 16.4967Z"
          fill={dark ? '#03045E' : 'white'}
        />
        <path
          d="M6.33166 12.4317L7.37666 15.4917C7.44666 15.695 7.63832 15.8317 7.85332 15.8317H10.8883C11.2333 15.8317 11.4767 15.4917 11.365 15.165L10.32 12.105C10.25 11.9017 10.0583 11.765 9.84332 11.765H6.80666C6.46166 11.7667 6.21999 12.105 6.33166 12.4317Z"
          fill={dark ? '#00B4D8' : 'white'}
        />
        <path
          d="M19.3517 19.9H16.315C15.97 19.9 15.7267 19.56 15.8383 19.2333L19.595 8.23999C19.665 8.03666 19.8567 7.89999 20.0717 7.89999H23.1067C23.4517 7.89999 23.695 8.23999 23.5833 8.56666L19.8267 19.56C19.7583 19.7633 19.5667 19.9 19.3517 19.9Z"
          fill={dark ? '#03045E' : 'white'}
        />
      </g>
      <defs>
        <clipPath id="clip0_7056_206635">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
Logo.defaultProps = { height: 42, width: 33, color: '#fff' }
export default Logo
