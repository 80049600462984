/* eslint-disable @typescript-eslint/ban-types */
import { ROUTES } from 'common/constants/routes'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'

type Props = {}

const data = [
  { id: 'hcm', name: 'Ho Chi Minh' },
  { id: 'hn', name: 'Ha Noi' },
  { id: 'seoul', name: 'Seoul' },
]

const JobByCity = (props: Props) => {
  const navigate = useNavigate()
  return (
    <S.ListJobContainer>
      {data &&
        data?.map((item: any) => (
          <S.Item onClick={() => navigate(`${ROUTES.JOB_ALL}?location=${item.id}`)} key={item.id}>
            {item.name}
          </S.Item>
        ))}
    </S.ListJobContainer>
  )
}

export default JobByCity
